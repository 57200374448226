<template>
  <div class="thumb">
    <ThumbPlayer
      :data="data"
      :ratio="layout === 'thumb_podcast' ? 1 : undefined"
      :title="title"
    />

    <div class="thumb-content">
      <Label
        v-if="data.acf.data && type === 'episode'"
        class="meta"
        :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
      />

      <!-- <Label
        v-if="type !== 'episode'"
        class="subtype"
        :data="{ value: type, size: 'xxs' }"
      /> -->

      <router-link
        :to="
          layout === 'thumb_podcast'
            ? $relativeUrl(data.acf.show.acf.resident_guest_programma[0].link)
            : data.link
              ? $relativeUrl(data.link)
              : $relativeUrl(data.url)
        "
        class="title-link"
      >
        <Title
          :data="{ value: title }"
          class="t-ellipsis"
        />
      </router-link>

      <Artist :data="data" />

      <div
        v-if="data.gds_taxonomies.tags && type === 'episode'"
        class="tags"
      >
        <Tags
          :data="data.gds_taxonomies.tags"
          lite
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThumbPlayer from '@/components/thumb/components/thumb-player';
import Artist from '@/components/thumb/components/artist';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Tags from '@/components/typo/tags';

export default {
  name: 'Thumb',
  components: {
    ThumbPlayer,
    Artist,
    Title,
    Label,
    Tags,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'thumb_medium',
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string' ? this.data.title : this.data.title.rendered;

      if (
        this.data.subtype === 'show'
        && title === this.data.acf.resident_guest_programma[0].title.rendered
      ) {
        return `${title} Radio Show`;
      }

      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
    type() {
      return this.data.subtype ? this.data.subtype : this.data.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  .title-link {
    display: block;
    margin-top: var(--spacer-xxs);

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .meta {
    display: block;
    margin-top: var(--spacer-xs);
  }
  .artist,
  .show-link {
    // margin-top: var(--spacer-xxs);
  }
  .show-link {
    display: block;
  }
  .tags {
    margin-top: var(--spacer-s);
  }

  &-content {
    position: relative;
  }

  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-d);
  }
}
</style>
