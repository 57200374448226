<template>
  <div :class="['thumb', 'thumb-result']">
    <router-link
      :to="$relativeUrl(data.url)"
      class="thumb-figure"
    >
      <Figure
        v-if="featuredImage"
        :data="{
          ...featuredImage,
          ratio: data.subtype === 'podcast' ? 1 : 0.84,
          align: alignImage,
        }"
        disable-caption
      />
    </router-link>

    <div class="thumb-content">
      <Label
        v-if="data.acf.data && data.subtype === 'episode'"
        class="meta"
        :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
      />

      <Label
        v-if="data.subtype !== 'episode'"
        class="subtype"
        :data="{ value: data.subtype, size: 'xxs' }"
      />

      <router-link
        :to="$relativeUrl(data.url)"
        :class="[
          'title-link',
          data.subtype !== 'episode' ? 'title-link-margin' : false,
        ]"
      >
        <Title
          :data="{ value: title, size: 's' }"
          class="t-ellipsis"
        />
      </router-link>

      <Artist
        :data="data"
        class="typo--xs"
        size="xs"
      />

      <div
        v-if="data.gds_taxonomies.tags && data.subtype === 'episode'"
        class="tags"
      >
        <Tags
          :data="data.gds_taxonomies.tags"
          theme="dark"
          lite
        />
      </div>
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Artist from '@/components/thumb/components/artist';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Tags from '@/components/typo/tags';

export default {
  name: 'Thumb',
  components: {
    Title,
    Artist,
    Label,
    Tags,
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    featuredImage() {
      const placeholder = {
        height: 450,
        mime_type: 'image/jpeg',
        subtype: 'jpeg',
        type: 'image',
        url: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        sizes: {
          xxl: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        },
        width: 800,
      };
      return this.data.gds_featured_image
        ? this.data.gds_featured_image
        : this.data.acf.show && this.data.acf.show.gds_featured_image
          ? this.data.acf.show.gds_featured_image
          : this.data.acf.show
          && this.data.acf.show.acf.resident_guest_programma
          && this.data.acf.show.acf.resident_guest_programma[0]
          && this.data.acf.show.acf.resident_guest_programma[0].gds_featured_image
            ? this.data.acf.show.acf.resident_guest_programma[0].gds_featured_image
            : placeholder;
    },
    alignImage() {
      return this.data.gds_featured_image
        ? this.data.acf.align_cover
        : this.data.acf.show && this.data.acf.show.gds_featured_image
          ? this.data.acf.align_cover
          : this.data.acf.show
          && this.data.acf.show.acf.resident_guest_programma
          && this.data.acf.show.acf.resident_guest_programma[0]
          && this.data.acf.show.acf.resident_guest_programma[0].gds_featured_image
            ? this.data.acf.align_cover
            : 'center';
    },
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;

      if (
        this.data.subtype === 'show'
        && title === this.data.acf.resident_guest_programma[0].title.rendered
      ) {
        return `${title} Radio Show`;
      }

      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px minmax(0, 1fr);
  column-gap: var(--column-gap-xxs);

  &-figure {
    display: block;
  }

  &-content {
    position: relative;
  }

  .title-link {
    display: block;

    &-margin {
      margin-right: 60px;
    }
  }
  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey);
  }
  .meta {
    display: block;
    margin-bottom: var(--spacer-xxs);
  }
  .artist {
    margin-top: var(--spacer-xxs);
  }
  .tags {
    margin-top: var(--spacer-xs);
  }
}
</style>
