<template>
  <component
    :is="avoidLink ? 'div' : 'router-link'"
    :class="[
      'thumb-player',
      status ? 'thumb-player--playing' : false,
      podcast ? 'thumb-player-no-figure' : false,
      canvas ? 'thumb-player-canvas' : false,
    ]"
    :to="
      !avoidLink
        ? data.link
          ? $relativeUrl(data.link)
          : data.url
            ? $relativeUrl(data.url)
            : undefined
        : undefined
    "
  >
    <Figure
      v-if="featuredImage && !podcast && !canvas && !hide"
      :data="{ ...featuredImage, ratio, align: data.acf.align_cover }"
      disable-caption
    />

    <Asteroid
      v-if="canvas"
      :data="featuredImage.url"
    />

    <inline-svg
      v-if="data.acf.mixcloud_iframe"
      class="icon"
      :src="require(`@/assets/svg/icons/${status ? 'pause' : 'play'}.svg`)"
      @click.native="fnEmbed"
    />
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

import { fixMixcloud, fixSpreaker } from '@/assets/js/utils';

import Figure from '@/components/media/figure';
import Asteroid from '@/components/media/asteroid';

export default {
  name: 'ThumbPlayer',
  components: {
    Figure,
    Asteroid,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    ratio: {
      type: Number,
      default: 0.564874552,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    podcast: {
      type: Boolean,
      default: false,
    },
    canvas: {
      type: Boolean,
      default: false,
    },
    avoidLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: false,
    };
  },
  computed: {
    ...mapGetters(['embedStatusById', 'embedById', 'embedStatus']),
    featuredImage() {
      const placeholder = {
        height: 450,
        mime_type: 'image/jpeg',
        subtype: 'jpeg',
        type: 'image',
        url: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        sizes: {
          xxl: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        },
        width: 800,
      };
      return this.data.gds_featured_image
        ? this.data.gds_featured_image
        : this.data.acf.show && this.data.acf.show.gds_featured_image
          ? this.data.acf.show.gds_featured_image
          : this.data.acf.show
            && this.data.acf.show.acf.resident_guest_programma
            && this.data.acf.show.acf.resident_guest_programma[0]
            && this.data.acf.show.acf.resident_guest_programma[0].gds_featured_image
            ? this.data.acf.show.acf.resident_guest_programma[0].gds_featured_image
            : placeholder;
    },
  },
  watch: {
    embedStatus: {
      immediate: true,
      handler() {
        this.status = this.embedStatusById(this.data.slug);
      },
    },
  },
  mounted() {
    this.$bus.$on('DELETE_EMBED', this.setStatus);
  },
  beforeDestroy() {
    this.$bus.$off('DELETE_EMBED', this.setStatus);
  },
  methods: {
    fnEmbed(e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.embedById(this.data.slug)) {
        const isSpreaker = this.data.acf.mixcloud_iframe.includes(
          'widget.spreaker.com',
        );
        const isMixcloud = this.data.acf.mixcloud_iframe.includes(
          'mixcloud.com/widget/',
        );

        const html = this.podcast && isSpreaker
          ? fixSpreaker(this.data.acf.mixcloud_iframe)
          : isMixcloud ? fixMixcloud(this.data.acf.mixcloud_iframe) : this.data.acf.mixcloud_iframe;

        const title = this.data.acf
          && this.data.acf.show.acf
          && this.data.acf.show.acf.resident_guest_programma[0]
          ? `<small>${this.data.acf.data}</small>   ${this.title}  —  ${this.data.acf.show.acf.resident_guest_programma[0].title.rendered}`
          : `<small>${this.data.acf.data}</small>   ${this.title}`;

        const titleText = this.data.acf
          && this.data.acf.show.acf
          && this.data.acf.show.acf.resident_guest_programma[0]
          ? `${this.data.acf.data} - ${this.title} — ${this.data.acf.show.acf.resident_guest_programma[0].title.rendered}`
          : `${this.data.acf.data} - ${this.title}`;

        this.$store.commit('SET_EMBED', {
          html,
          provider:
            this.podcast && isSpreaker ? 'spreaker-iframe' : isMixcloud ? 'mixcloud-iframe' : 'generic-iframe',
          id: this.data.slug,
          status: null,
          title,
          link: this.data.link ? this.data.link : this.data.url,
        });
        if (window.gtag) {
          window.gtag('event', 'play_episode', {
            title_complete: titleText,
            date: this.data.acf.data,
            title: this.title,
            show: this.data.acf
              && this.data.acf.show ? this.data.acf.show.title.rendered : null,
            artist: this.data.acf
              && this.data.acf.show.acf
              && this.data.acf.show.acf.resident_guest_programma[0] ? this.data.acf.show.acf.resident_guest_programma[0].title.rendered : null,
          });
        }
      } else {
        this.$bus.$emit('TOGGLE_PLAY', this.data.slug);
      }
    },
    setStatus(id) {
      if (id === this.data.slug) {
        this.status = false;
      }
    },
  },
};
</script>

<style lang="scss">
.thumb-player {
  color: #6b6b6b;
  display: block;

  position: relative;

  .icon {
    position: absolute;
    pointer-events: auto;
    top: 50%;
    left: 50%;
    width: 70%;
    // height: 100%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    mix-blend-mode: difference;
    max-width: 250px;
    opacity: 0;
  }

  &:hover,
  &--playing {
    .icon {
      transition: 0.2s opacity $ease-custom;
      opacity: 1;
    }
  }

  @include mq($and: $not-hover) {
    .icon {
      transition: 0.2s opacity $ease-custom;
      opacity: 1;
    }
  }

  &-no-figure {
    color: var(--white);

    .icon {
      top: 0;
      left: 0;
      mix-blend-mode: unset;
      opacity: 1;
      transform: none;
      width: 100%;
      max-width: 80px;
      height: auto;
    }
  }

  &-canvas {
    height: 100%;
    min-height: 680px;

    @include mq($until: s) {
      min-height: 360px;
    }

    * {
      position: absolute;
      width: 100% !important;
      top: 0;
      height: calc(100% + 60px) !important;
      // height: 100%;

      @include mq($until: s) {
        height: calc(100% + 30px) !important;
        top: -5px;
      }
    }
  }
}
</style>
