<template>
  <Grid
    class="thumb"
    align="start"
    row-gap="xs"
  >
    <Column :size="{ default: 12, s: 4 }">
      <router-link
        :to="$relativeUrl(data.link)"
        class="thumb-media"
      >
        <Figure
          v-if="data.gds_featured_image"
          :data="{
            ...data.gds_featured_image,
            ratio: 1,
            align: data.acf.align_cover,
          }"
          disable-caption
        />
      </router-link>
    </Column>

    <Column
      class="thumb-content"
      :size="{ default: 12, s: 8 }"
    >
      <div
        v-if="!$mq.isMobile"
        class="pre-row"
      >
        <div class="separator--row" />
      </div>

      <div class="thumb-heading">
        <router-link
          :to="$relativeUrl(data.link)"
          class="title-link"
        >
          <Title
            :data="{ value: title, size: 'l' }"
            class="t-ellipsis"
          />
        </router-link>

        <Flex
          class="artist"
          align="baseline"
          nowrap
        >
          <router-link
            v-if="
              data.acf.resident_guest_programma &&
                data.acf.resident_guest_programma[0]
            "
            :to="$relativeUrl(data.acf.resident_guest_programma[0].link)"
          >
            <Title
              :data="{
                value: data.acf.resident_guest_programma[0].title.rendered,
                size: 'm',
              }"
            />
          </router-link>

          <Title
            v-else-if="data.acf.subtitle"
            :data="{ value: data.acf.subtitle, size: 'm' }"
          />
        </Flex>
      </div>

      <div
        v-if="data.gds_taxonomies.tags"
        class="thumb-tags"
      >
        <Tags
          :data="data.gds_taxonomies.tags"
          lite
        />
      </div>

      <RichText
        v-if="data.content"
        class="thumb-richtext"
        :data="{ value: data.content.rendered, size: 's' }"
      />
    </Column>
  </Grid>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Tags from '@/components/typo/tags';

export default {
  name: 'ThumbSinglePodcast',
  components: {
    Figure,
    Title,
    RichText,
    Tags,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  .title-link {
    display: block;

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .artist {
    // margin-top: var(--spacer-xxs);
  }
  &-tags {
    margin-bottom: var(--spacer-s);
  }

  &-media {
    display: block;
  }

  &-content {
    position: relative;
    min-height: 100%;
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
  }

  &-richtext {
    @include mq(m) {
      max-width: 80%;
    }
  }

  &-links {
    margin-top: var(--spacer-s);
    align-self: end;
  }

  &-heading {
    margin-bottom: var(--spacer-s);
    min-height: 60px;
    @include mq(m) {
      margin-bottom: 0;
      min-height: 120px;
    }
  }
}
</style>
