<template>
  <component
    :is="data.id"
    :data="data"
  />
</template>

<script>
import Calendar from '@/components/blocks/custom/calendar';
import Asteroid from '@/components/blocks/custom/asteroid';

export default {
  name: 'Custom',
  components: {
    Asteroid,
    Calendar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
