<template>
  <Spacer
    class="calendar"
    tag="section"
  >
    <Spacer bottom="xl">
      <Content
        v-if="
          data.title ||
            (data.text && !(schedules && schedules.length))
        "
        :data="{
          title: data.title ? data.title : undefined,
          rich_text:
            !(schedules && schedules.length) > 0
              ? data.text
              : undefined,
          theme: 'hero',
        }"
      />
    </Spacer>
    <Spacer
      :style="{ '--rows': hours.minutes.length }"
      x="xs"
    >
      <div
        v-if="schedules && schedules.length > 0"
        class="calendar-wrapper"
      >
        <Grid
          class="hours typo--xs"
          grid="1"
          row-gap="xs"
        >
          <div
            v-for="hour in hours.fixed"
            :key="hour"
            class="hour"
          >
            <span>{{ hourFormatted(hour) }}</span>
          </div>
        </Grid>
        <Grid
          :col="{ default: 12, m: schedulesLength }"
          row-gap="xs"
          class="schedule-wrapper"
        >
          <div
            v-if="$mq.isMobile"
            ref="headingWrapper"
            class="heading"
          >
            <div
              ref="slider"
              class="heading-wrapper swiper-container"
            >
              <Flex
                class="swiper-wrapper"
                nowrap
              >
                <div
                  v-for="(schedule, scheduleIndex) in schedules"
                  :key="scheduleIndex"
                  :class="[
                    'swiper-slide',
                    'heading-title',
                    scheduleVisible === scheduleIndex
                      ? 'heading-title--active'
                      : false,
                  ]"
                >
                  <Cta
                    :data="{
                      title: schedule.acf.data,
                      size: 'xs',
                      fn: () => setScheduleVisible(scheduleIndex),
                    }"
                  />
                </div>
              </Flex>
            </div>
          </div>

          <div
            v-for="(schedule, scheduleIndex) in schedules"
            v-show="
              !$mq.isMobile || scheduleVisible === scheduleIndex
            "
            :key="schedule.id"
            class="schedule"
          >
            <div class="heading u-hidden--until-m">
              <div class="heading-wrapper">
                <div class="separator--row" />
                <Label
                  class="heading-label"
                  :data="{ value: schedule.acf.data, size: 'xs' }"
                />
              </div>
            </div>
            <Grid
              grid="1"
              row-gap="xs"
              class="schedule"
            >
              <div
                v-for="(session, index) in schedule.acf.schedule"
                :key="index"
                :class="[
                  'calendar-card',
                  isLive(schedule.acf.data, index)
                    ? 'calendar-card--active'
                    : false,
                ]"
                :style="
                  placeInGrid(session.ora_inizio, session.ora_fine)
                "
              >
                <component
                  :is="session.episode ? 'router-link' : 'div'"
                  :to="
                    session.episode
                      ? $relativeUrl(session.episode.link)
                      : undefined
                  "
                >
                  <Title
                    :data="{ value: session.titolo, size: 's' }"
                  />
                </component>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </Spacer>
  </Spacer>
</template>

<script>
import Swiper from 'swiper';

import { mapGetters } from 'vuex';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';
import Content from '@/components/blocks/content';
import Title from '@/components/typo/title';

export default {
  name: 'Calendar',
  components: {
    Label,
    Title,
    Cta,
    Content,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scheduleVisible: 0,
      hours: {
        start: [],
        end: [],
        fixed: [],
        first: null,
        minutes: [],
      },
      deltaX: 0,
      slider: null,
    };
  },
  computed: {
    ...mapGetters(['schedules', 'now']),
    schedulesLength() {
      return 12 / this.schedules.length;
    },
  },
  created() {
    this.initCalendar();
    this.$nextTick(() => {
      this.slider = new Swiper(this.$refs.slider, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        cssMode: true,
      });
    });
  },
  methods: {
    initCalendar() {
      this.schedules.forEach((item) => {
        if (Array.isArray(item.acf.schedule)) {
          this.hours.start.push(
            ...item.acf.schedule.map((el) => el.ora_inizio),
          );
          this.hours.end.push(
            ...item.acf.schedule.map((el) => (el.ora_fine === '00:00' ? '24:00' : el.ora_fine)),
          );
        }
      });

      const start = this.hours.start
        .map((hour) => parseInt(hour.split(':')[0], 10))
        .sort((a, b) => a - b)[0];
      const last = this.hours.end
        .map((hour) => parseInt(hour.split(':')[0], 10))
        .sort((a, b) => b - a)[0];
      this.hours.first = start;

      for (let index = start; index <= last; index += 1) {
        this.hours.fixed.push(index);
        for (let mIndex = 0; mIndex < 12; mIndex += 1) {
          this.hours.minutes.push(mIndex);
        }
      }
    },
    hourFormatted(hour) {
      const hourVal = hour === 24 ? 0 : hour;
      const zero = hourVal < 10 ? '0' : '';
      return `${zero}${hourVal.toString()}:00`;
    },
    placeInGrid(val, val2) {
      const endVal = val2 === '00:00' ? '24:00' : val2;

      const splittedVal = val.split(':');
      const splittedVal2 = endVal.split(':');

      const startHour = parseInt(splittedVal[0], 10);
      const endHour = parseInt(splittedVal2[0], 10);

      const startMinutes = parseInt(splittedVal[1], 10);
      const endMinutes = parseInt(splittedVal2[1], 10);

      const start = 12 * (startHour - this.hours.first) + startMinutes / 5;
      const end = 12 * (endHour - this.hours.first) + endMinutes / 5;

      return {
        gridRowStart: start + 1,
        // gridRowEnd: start === 0 ? end + 2 : end + 1,
        gridRowEnd: end + 1,
      };
    },
    setScheduleVisible(index) {
      this.slider.slideTo(index);
      this.scheduleVisible = index;
    },
    isLive(dayString, index) {
      const [d, m, y] = dayString.split(', ')[1].split('.');
      const sep = this.$mq.isIOS || this.$mq.isSafari ? '/' : '-';
      const day = `${m}${sep}${d}${sep}20${y}`;

      if (
        Date.parse(day)
          === new Date(
            new Date().toLocaleString('en', {
              timeZone: 'Europe/Rome',
            }),
          ).setHours(0, 0, 0, 0)
        && this.now
      ) {
        return this.now.currentIndex === index;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  &-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @include mq(m) {
      display: flex;
      flex-flow: row nowrap;
      // align-items: flex-start;
      column-gap: var(--column-gap-xs);
    }
  }

  .hours {
    grid-column-start: 1;
    grid-column-end: 3;

    padding-top: 30px;
    // overflow: hidden;

    @include mq(s) {
      padding-top: 40px;
    }

    @include mq(m) {
      flex: 0 0 100px;
    }
  }

  &-card,
  .hour {
    // min-height: 78px;
    // height: 100%;

    * {
      height: 100%;
    }
  }

  .hour {
    // padding: var(--spacer-xxs) 0;
    grid-row-start: auto;
    grid-row-end: span 12;
    position: relative;
    min-height: 80px;

    * {
      // padding: var(--spacer-xxs) 0;
      position: absolute;
      width: 100%;
    }
  }

  &-card {
    grid-column: 1 / -1;
    position: relative;

    & > * {
      background-color: var(--grey-xxxd);
      padding: var(--spacer-xxs);
      position: absolute;
      width: 100%;
      height: 100%;
    }

    a {
      @include mq($and: $hover) {
        transition: background-color 0.2s $ease-custom;
        &:hover {
          background-color: var(--grey-xxd);
        }
      }
    }

    &--active {
      & > * {
        background-color: var(--grey-xd);
      }

      a {
        @include mq($and: $hover) {
          &:hover {
            background-color: var(--grey-d);
          }
        }
      }
    }
  }

  .schedule-wrapper {
    position: relative;
    flex: 1 0 0;
    width: 100%;

    grid-column-start: 3;
    grid-column-end: -1;
  }

  .schedule {
    position: relative;
    grid-template-rows: repeat(var(--rows), auto);
  }

  .heading {
    top: 80px;
    position: sticky;

    height: 30px;
    overflow-x: scroll;

    @include mq(s) {
      overflow: auto;
      height: 40px;
    }

    grid-row: 1;
    grid-column: 1;
    // transform: translateY(-100%);
    // position: absolute;
    z-index: 2;

    @include mq(m) {
      width: 100%;
    }

    .separator--row {
      margin-bottom: var(--spacer-xxs);
    }

    &-wrapper {
      position: relative;
    }

    &-label {
      padding-left: var(--spacer-xxs);
    }

    &-title {
      flex: 0 0 auto;
      width: auto;
      color: var(--grey-xd);

      &:last-child {
        width: 100%;
      }

      &--active {
        color: var(--white);
      }
    }
  }
}
</style>
