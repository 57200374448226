<template>
  <div class="carousel">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Carousel',
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'center',
        cellSelector: '.item',
        imagesLoaded: true,
      },
    };
  },
};
</script>

<style scoped lang="scss">
.item {
  width: 100%;
}
</style>
