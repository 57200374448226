var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.post.acf.background)?_c('Figure',{staticClass:"background",attrs:{"data":{
      ..._vm.post.acf.background,
    },"disable-caption":""}}):_vm._e(),_c('div',{staticClass:"main-wrapper"},[(!_vm.logged)?_c('div',{staticClass:"login"},[_c('form',{attrs:{"method":"POST"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"id":"password","type":"password","name":"password","required":"","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('Cta',{attrs:{"data":{
            title: 'Entra',
            fn: () => {},
          },"theme":'button'}})],1)]):_c('div',[(_vm.post.gds_featured_image && _vm.post.acf.type === 'client')?_c('div',{staticClass:"logo"},[_c('Figure',{attrs:{"data":{
            ratio: true,
            default: _vm.post.gds_featured_image,
            mobile: _vm.post.acf.featured_image_mobile,
          },"disable-caption":""}})],1):_c('Title',{attrs:{"data":{ value: _vm.post.title.rendered, size: 'l' }}}),_c('Grid',[(_vm.post.acf.current_track)?_c('Column',{attrs:{"size":{ default: 12 }}},[_c('p',{staticClass:"metadata",style:({ opacity: _vm.playing ? 1 : 0 })},[_c('Spacer',{attrs:{"use-margin":"","bottom":"xxs"}},[_c('Label',{attrs:{"data":{ value: 'Now Playing', size: 'xxs' }}})],1),_c('Spacer',{attrs:{"x":"xs"}},[_c('div',{staticClass:"typo--xl",domProps:{"innerHTML":_vm._s(_vm.metadata || '&nbsp;')}})])],1)]):_vm._e()],1),_c('div',[_c('Column',{attrs:{"size":{ default: 12 }}},[_c('Cta',{attrs:{"theme":'radio',"data":{
              title: _vm.label,
              fn: () => {
                _vm.toggle();
              },
            }}}),(_vm.fallback)?_c('audio',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fallbackEl",attrs:{"src":_vm.post?.acf?.url}}):_vm._e()],1)],1),_c('Wrapper',{staticClass:"copyright",attrs:{"size":'m'}},[_c('div',{staticClass:"typo--xs",domProps:{"innerHTML":_vm._s(_vm.footer.copyright)}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }