<template>
  <component
    :is="mergeProps.tag"
    :class="['label', `typo--${mergeProps.size}`]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Label',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        tag: 'h3',
        size: 'lm',
      };

      Object.keys(this.data).forEach((key) => this.data[key] === undefined && delete this.data[key]);
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style>

</style>
