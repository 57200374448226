var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.avoidLink ? 'div' : 'router-link',{tag:"component",class:[
    'thumb-player',
    _vm.status ? 'thumb-player--playing' : false,
    _vm.podcast ? 'thumb-player-no-figure' : false,
    _vm.canvas ? 'thumb-player-canvas' : false,
  ],attrs:{"to":!_vm.avoidLink
      ? _vm.data.link
        ? _vm.$relativeUrl(_vm.data.link)
        : _vm.data.url
          ? _vm.$relativeUrl(_vm.data.url)
          : undefined
      : undefined}},[(_vm.featuredImage && !_vm.podcast && !_vm.canvas && !_vm.hide)?_c('Figure',{attrs:{"data":{ ..._vm.featuredImage, ratio: _vm.ratio, align: _vm.data.acf.align_cover },"disable-caption":""}}):_vm._e(),(_vm.canvas)?_c('Asteroid',{attrs:{"data":_vm.featuredImage.url}}):_vm._e(),(_vm.data.acf.mixcloud_iframe)?_c('inline-svg',{staticClass:"icon",attrs:{"src":require(`@/assets/svg/icons/${_vm.status ? 'pause' : 'play'}.svg`)},nativeOn:{"click":function($event){return _vm.fnEmbed.apply(null, arguments)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }