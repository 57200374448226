import Vue from 'vue';
import { timeline } from 'motion';

export const enterOverlay = (el, done) => {
  const offset = Vue.prototype.$mq.isMobile ? '0px' : '40px';
  const bars = Vue.prototype.$mq.isMobile ? '60px' : '50px';

  const isEmbed = document
    .querySelector('#app')
    .classList.contains('is-embed-active');
  const embed = Vue.prototype.$mq.isMobile && isEmbed ? 'var(--embed, 90px)' : '0px';

  let sequence = [];
  el.style.willChange = 'height';
  el.style.height = '0px';
  el.children[0].style.clipPath = 'inset(-25px 0 100% 0)';
  sequence = [
    [
      el,
      { height: `calc((var(--vh) * 100) - ${offset} - ${bars} - ${embed})` },
    ],
    [el.children[0], { clipPath: 'inset(-25px 0 0% 0)' }, { at: 0 }],
  ];

  timeline(sequence, {
    defaultOptions: {
      duration: 0.3,
      ease: [0.19, 1, 0.22, 1],
      allowWebkitAcceleration: true,
    },
  }).finished.then(() => {
    el.removeAttribute('style');
    el.children[0].removeAttribute('style');
    done();
  });
};

export const leaveOverlay = (el, done) => {
  let sequence = [];

  sequence = [
    [el.children[0], { clipPath: 'inset(0 0 100% 0)' }],
    [el, { height: 0, overflow: 'hidden' }, { at: 0 }],
  ];

  timeline(sequence, {
    defaultOptions: {
      duration: 0.3,
      ease: [0.19, 1, 0.22, 1],
      allowWebkitAcceleration: true,
    },
  }).finished.then(done);
};

export const enterChildOverlay = (el, done) => {
  el.style.willChange = 'transform';
  el.style.transform = 'translateY(100%)';

  const sequence = [[el, { transform: 'translateY(0%)' }]];

  timeline(sequence, {
    defaultOptions: {
      duration: 0.3,
      ease: [0.19, 1, 0.22, 1],
      allowWebkitAcceleration: true,
    },
  }).finished.then(() => {
    el.removeAttribute('style');
    done();
  });
};
