<template>
  <Flex
    gap
    row-gap="xxs"
    column-gap="xxs"
    nowrap
    class="tags"
  >
    <Cta
      v-for="tag in tags"
      :key="tag.term_id"
      :class="['tag', `tag--${theme}`]"
      :data="{ title: tag.name, size: 'tag', url: setUrl(tag) }"
    />
  </Flex>
</template>

<script>
import Cta from '@/components/typo/cta';

export default {
  name: 'Tags',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
    lite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tags() {
      const data = [...this.data].filter((tag) => tag.term_id !== 44);
      return this.lite ? data.slice(0, 3) : data;
    },
  },
  methods: {
    setUrl(tag) {
      const query = {
        year: 'all',
        genre: tag.parent > 0 ? tag.parent : tag.term_id,
        subgenre: tag.parent > 0 ? tag.term_id : undefined,
        page: 1,
      };

      return {
        name: 'Search',
        query,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  overflow-x: scroll;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tag {
  border-radius: 20px;
  padding: 0px 14px 2px;

  &--light {
    color: var(--black);
    background: var(--white);
  }

  &--dark {
    color: var(--white);
    background: var(--black);
  }
}
</style>
