<template>
  <router-link
    :class="[
      'thumb',
      `thumb--${layout}`,
      index === 0 || data.preview_thumb ? 'thumb--large' : false,
    ]"
    :to="$relativeUrl(data.link)"
  >
    <div class="thumb-cover">
      <Figure
        v-if="data.gds_featured_image"
        :data="{
          ...data.gds_featured_image,
          ratio: 0.5625,
          align:
            data.acf && data.acf.align_cover ? data.acf.align_cover : undefined,
        }"
        disable-caption
      />
    </div>

    <Spacer
      class="thumb-content"
      top="xs"
    >
      <div class="thumb-content-inner">
        <Label
          v-if="data.date_format && data.type !== 'galaxy_express'"
          class="meta"
          :data="{ value: data.date_format, size: 'xxs', tag: 'time' }"
        />

        <Title
          :data="{ value: title, size: large && !$mq.isMobile ? 'xl' : 'l' }"
          class="t-ellipsis"
        />

        <Label
          v-if="data.acf && data.acf.data"
          class="meta-event"
          :data="{ value: data.acf.data, size: 'm', tag: 'time' }"
        />

        <div
          v-if="content && !thumbProject && !thumbEvent"
          class="excerpt"
        >
          <Richtext
            :data="{ value: content, size: large && !$mq.isMobile ? 'm' : 's' }"
            :class="[
              layout === 'thumb_archive_half'
                ? 'content-clamp content-clamp--4'
                : false,
            ]"
          />
        </div>
      </div>

      <Cta
        :data="{
          title: 'Leggi di più',
          url: data.link,
          size: 's',
        }"
        theme="button"
      />
    </Spacer>
  </router-link>
</template>

<script>
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Richtext from '@/components/typo/richtext';
import Figure from '@/components/media/figure';

export default {
  name: 'Thumb',
  components: {
    Title,
    Label,
    Richtext,
    Cta,
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'thumb_medium',
    },
    index: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    thumbProject() {
      return this.data.type === 'partner' || this.data.type === 'project';
    },
    thumbEvent() {
      return this.data.type === 'event';
    },
    large() {
      return (
        this.layout === 'thumb_full'
        || (this.layout === 'thumb_archive'
          && (this.index === 0 || this.data.preview_thumb))
      );
    },
    title() {
      return typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
    },
    type() {
      return this.data.subtype ? this.data.subtype : this.data.type;
    },
    content() {
      return this.data.content
        ? this.data.content.rendered.split('<!--more-->')[0]
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;

  .excerpt {
    margin-top: var(--spacer-xs);

    ::v-deep {
      strong {
        display: inline-block;
        margin-bottom: var(--spacer-xs);
        @extend %typo--m;
      }
    }
  }
  .cta {
    margin-top: var(--spacer-l);
  }

  .meta-event {
    margin-top: var(--spacer-xs);
  }

  &-content {
    position: relative;
  }

  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-d);
  }

  &--thumb_full {
    .picture {
      @include mq(m) {
        width: calc(100% + 20px);
        margin-left: -10px;
      }
    }
  }

  .picture {
    @include mq($until: s) {
      width: calc(100% + 20px);
      margin-left: -10px;
    }
  }

  &--thumb_archive.thumb--large {
    .picture {
      @include mq(m) {
        width: calc(100% + 20px);
        margin-left: -10px;
      }
    }

    .excerpt {
      ::v-deep {
        strong {
          @extend %typo--l;
        }
      }
    }
  }

  &.thumb--large {
    .excerpt {
      ::v-deep {
        strong {
          @extend %typo--l;
        }
      }
    }
  }

  &--thumb_archive_half {
    height: 100%;
    display: flex;
    flex-direction: column;

    .thumb-content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .thumb-content-inner {
      flex: 1;
    }
  }
}
</style>
