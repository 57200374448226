<template>
  <div class="studio-hero">
    <Wrapper
      size="m"
      boxed
    >
      <Richtext
        class="t-center"
        :data="{ value: data.rich_text, size: 'xl' }"
      />
    </Wrapper>
    <Asteroid
      class="studio-hero--asteroid"
      :data="featuredImage.url"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Asteroid from '@/components/media/asteroid';
import Richtext from '@/components/typo/richtext.vue';

export default {
  name: 'StudioHero',
  components: {
    Asteroid,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['now']),
    featuredImage() {
      if (this.data.image) {
        return this.data.image;
      }

      return {
        height: 450,
        mime_type: 'image/jpeg',
        subtype: 'jpeg',
        type: 'image',
        url: `${process.env.VUE_APP_PROXY_TARGET}/wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        sizes: {
          xxl: `${process.env.VUE_APP_PROXY_TARGET}/wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        },
        width: 800,
      };
    },
  },
};
</script>

<style lang="scss">
.studio-hero {
  position: relative;
  height: 100%;
  min-height: 500px;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  &--asteroid {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include mq($until: s) {
      top: 50%;
      height: calc(var(--vw, 1vw) * 100);
      transform: translateY(-50%);
    }
  }
}

[data-theme="about"] {
  main {
    & > *:not(.studio-hero) {
      position: relative;
      z-index: 2;
      pointer-events: none;

      a {
        pointer-events: auto;
      }
    }
  }
}
</style>
