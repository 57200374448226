/* eslint-disable no-undef */

import difference from 'lodash.difference';
import orderBy from 'lodash.orderby';

import {
  fetchItems, fetchSingle, fetchSearch, fetchSingleById, sendAjax,
} from '@/api/wp';

export default {
  async getSingleById(_, { type, id }) {
    const response = await fetchSingleById({ type, params: { id } }).then(({ data }) => data);
    return response;
  },
  async getSingleBySlug({ commit, getters }, {
    type, slug, lang, password,
  }) {
    let response = null;
    const responseFromState = getters.singleBySlug({
      type,
      slug,
      lang,
      password,
    });
    if (!responseFromState || responseFromState.lite) {
      await fetchSingle({ type, params: { slug, lang, password } }).then(({ data: [item] }) => {
        if (item) {
          commit('ADD_ITEM', {
            type,
            item,
            lang,
            lite: false,
          });

          // show
          if (item.acf?.show) {
            commit('ADD_ITEM', { type: 'shows', item: item.acf.show, lang });
          }
          // artist etc
          if (item.acf?.resident_guest_programma) {
            const { postTypes } = __VUE_WORDPRESS__.state;
            item.acf.resident_guest_programma.forEach((artist) => {
              commit('ADD_ITEM', {
                type: postTypes[artist.type].rest_base,
                item: artist,
                lang,
              });
            });
          }
          response = item;
        }
      });
    } else {
      response = responseFromState;
    }
    return response;
  },
  async getItems({ getters, commit }, { type, params }) {
    let response = null;
    const responseFromState = getters.request({ type, params });
    const responseWithParams = responseFromState && params.slug && responseFromState.length !== params.slug.length;

    if (!responseFromState || responseWithParams) {
      let itemsFromStore = [];
      let prevParamsSlug = null;
      if (responseWithParams) {
        prevParamsSlug = params.slug;
        const slugFromStore = difference(params.slug, responseFromState);
        Object.assign(params, { slug: slugFromStore });
        itemsFromStore = responseFromState.map((slug) => getters.singleBySlug({ type, slug, lang: params.lang }));
      }
      await fetchItems({ type, params }).then(
        ({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
          items.forEach((item) => {
            commit('ADD_ITEM', {
              type,
              item,
              lite: params.lite,
              lang: params.lang,
            });
            if (item.acf) {
              // show
              if (item.acf.show) {
                commit('ADD_ITEM', {
                  type: 'shows',
                  item: item.acf.show,
                  lang: params.lang,
                });
              }
              // artist etc
              if (item.acf.resident_guest_programma) {
                const { postTypes } = __VUE_WORDPRESS__.state;
                item.acf.resident_guest_programma.forEach((artist) => {
                  commit('ADD_ITEM', {
                    type: postTypes[artist.type].rest_base,
                    item: artist,
                    lang: params.lang,
                  });
                });
              }
            }
          });
          commit('ADD_REQUEST', {
            type,
            request: {
              type,
              params,
              total: parseInt(total, 10),
              totalPages: parseInt(totalPages, 10),
              data: items.map((i) => i.slug),
            },
          });

          const sortResponse = responseWithParams
            ? orderBy(
              [...items, ...itemsFromStore],
              [(i) => prevParamsSlug.findIndex((e) => e === i.slug)],
            )
            : items;

          const parseResponse = params.per_page
            ? sortResponse.slice(0, params.per_page)
            : sortResponse;

          response = parseResponse;
        },
      );
    } else {
      const parseResponseFromState = params.per_page
        ? responseFromState.slice(0, params.per_page)
        : responseFromState;
      response = parseResponseFromState.map((slug) => getters.singleBySlug({ type, slug, lang: params.lang }));
    }

    return response;
  },
  async getSearch(_, request) {
    let response = null;
    // commit('SET_AJAX_LOADING', true);
    await fetchSearch(request).then((res) => {
      // commit('SET_AJAX_LOADING', false);

      const data = res.data.filter(
        (item) => item.subtype !== 'show'
          || (item.subtype === 'show'
            && item.acf.resident_guest_programma[0].gds_taxonomies.artist_category
            && item.acf.resident_guest_programma[0].gds_taxonomies.artist_category[0].slug
              === 'guest'
            && item.acf.resident_guest_programma[0].title.rendered.toLowerCase()
              !== item.title.toLowerCase())
          || (item.acf.resident_guest_programma[0].gds_taxonomies.artist_category
            && item.acf.resident_guest_programma[0].gds_taxonomies.artist_category[0].slug
              !== 'guest')
          || !item.acf.resident_guest_programma[0].gds_taxonomies.artist_category,
      );
      const total = parseInt(res.headers['x-wp-total'], 10) - (res.data.length - data.length);

      response = {
        ...res,
        headers: {
          ...res.headers,
          'x-wp-total': total,
        },
        data,
      };
    });

    return response;
  },
  sendRequest(_, request) {
    return sendAjax(request);
  },
};
