var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}],class:`archive--${_vm.data.layout}`,attrs:{"x":!_vm.data.slider ? 'xs' : undefined,"bottom":_vm.data.layout === 'thumb_medium' && _vm.data.post_type === 'partner'
      ? '0'
      : _vm.data.groups
        ? 'xl'
        : 'xxl'}},[_c('Grid',{attrs:{"col":_vm.data.layout !== 'thumb_podcast_episode' ||
        (_vm.data.layout === 'thumb_podcast_episode' && _vm.groups.length > 1)
        ? '12'
        : undefined,"row-gap":"0","column-gap":"0"}},[_c('Spacer',{attrs:{"x":_vm.data.slider ? 'xs' : undefined}},[(
          (!_vm.data.groupBy || _vm.groups.length < 2) &&
            _vm.data.layout !== 'thumb_podcast_episode' &&
            _vm.data.layout !== 'thumb_podcast_archive' &&
            _vm.data.layout !== 'thumb_half' &&
            _vm.data.layout !== 'thumb_archive' &&
            !(_vm.data.layout === 'thumb_archive_half' && _vm.data.post_type === 'galaxy_express')
        )?_c('Grid',{staticClass:"pre-row",attrs:{"col":_vm.data.layout === 'thumb_large' ? '12' : _vm.gridCol}},[_c('div',{staticClass:"separator--row"}),(
            _vm.data.layout !== 'thumb_large' &&
              _vm.data.layout !== 'thumb_extralarge' &&
              _vm.data.layout !== 'thumb_full'
          )?_c('div',{class:[
            'separator--row',
            _vm.data.layout !== 'thumb_podcast' ? 'u-hidden--until-s' : false,
          ]}):_vm._e(),(
            _vm.data.layout !== 'thumb_large' &&
              _vm.data.layout !== 'thumb_extralarge' &&
              _vm.data.layout !== 'thumb_full' &&
              _vm.data.layout !== 'thumb_archive_half'
          )?_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"}):_vm._e(),(
            _vm.data.layout !== 'thumb_large' &&
              _vm.data.layout !== 'thumb_extralarge' &&
              _vm.data.layout !== 'thumb_full' &&
              _vm.data.layout !== 'thumb_archive_half'
          )?_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"}):_vm._e()]):_vm._e()],1),_c('Spacer',{attrs:{"x":_vm.data.slider ? 'xs' : undefined}},[(_vm.title)?_c('Column',{attrs:{"size":_vm.data.layout === 'thumb_podcast_episode' ? { default: 12, s: 4 } : undefined}},[_c('div',{staticClass:"wrapper-cta title-archive"},[(_vm.data.cta)?_c('Cta',{staticClass:"link-cta",attrs:{"data":_vm.data.cta}}):_vm._e(),_c('Flex',{attrs:{"justify":"space-between","nowrap":"","gap":""}},[_c('Title',{staticClass:"t-uppercase",attrs:{"data":{ value: _vm.title, size: 'l' }}}),(_vm.data.cta)?_c('Cta',{attrs:{"data":_vm.data.cta,"theme":"button"}}):_vm._e()],1),(_vm.data.subtitle)?_c('Title',{staticClass:"subtitle-archive wrapper--l",attrs:{"data":{ value: _vm.data.subtitle, size: 'm' }}}):_vm._e()],1)]):_vm._e()],1),(!_vm.data.groupBy || _vm.groups.length < 2)?[_c('Column',{ref:"grid",class:[_vm.data.slider ? 'swiper-container' : false],attrs:{"size":_vm.data.layout === 'thumb_podcast_episode' ? { default: 12, s: 8 } : undefined}},[(_vm.items.length > 0)?_c('Grid',{class:['grid-items', _vm.data.slider ? 'swiper-wrapper' : false],style:(_vm.data.layout === 'thumb_large' ? { '--last-line': _vm.items.length + 1 } : false),attrs:{"col":_vm.data.layout !== 'thumb_large' && _vm.data.layout !== 'thumb_archive' ? _vm.gridCol : undefined,"row-gap":_vm.data.layout === 'thumb_podcast_episode' ? 'l' : undefined}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:[
              'item',
              item.preview_thumb ? 'item--large' : false,
              _vm.data.slider ? 'swiper-slide' : false,
            ]},[(
                _vm.data.layout === 'thumb_large' || (_vm.data.layout === 'thumb_small' && _vm.$mq.isMobile)
              )?_c('div',{staticClass:"separator--row"}):_vm._e(),_c(_vm.thumb(item.type, index),{tag:"component",attrs:{"data":item,"layout":_vm.data.layout,"index":index}})],1)}),0):_vm._e(),(
            _vm.items.length > 0 && _vm.data.layout === 'thumb_medium' && _vm.data.post_type === 'partner'
          )?_c('Grid',{class:['grid-items', _vm.data.slider ? 'swiper-wrapper' : false, 'grid-end'],style:(_vm.data.layout === 'thumb_large' ? { '--last-line': _vm.items.length + 1 } : false),attrs:{"col":_vm.data.layout !== 'thumb_large' && _vm.data.layout !== 'thumb_archive' ? _vm.gridCol : undefined,"row-gap":_vm.data.layout === 'thumb_podcast_episode' ? 'l' : undefined}},_vm._l((_vm.$mq.isMobile ? 2 : 4),function(i){return _c('div',{key:i,staticClass:"separator--row"})}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.slider && !_vm.$mq.isMobile),expression:"data.slider && !$mq.isMobile"}],class:{
            'swiper-button-prev': true,
            'swiper-button--thumb-small': _vm.data.layout === 'thumb_small',
          }},[(!_vm.$mq.isMobile)?_c('PrevButton'):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.slider && !_vm.$mq.isMobile),expression:"data.slider && !$mq.isMobile"}],class:{
            'swiper-button-next': true,
            'swiper-button--thumb-small': _vm.data.layout === 'thumb_small',
          }},[(!_vm.$mq.isMobile)?_c('NextButton'):_vm._e()],1)],1)]:(_vm.groups && _vm.groups.length >= 2)?[_c('Grid',{attrs:{"col":"12"}},_vm._l((_vm.groups),function(group){return _c('Grid',{key:group.id,attrs:{"row-gap":"0","col":_vm.data.layout !== 'thumb_podcast_episode' ? '12' : undefined}},[(_vm.data.layout !== 'thumb_podcast_episode')?_c('Grid',{staticClass:"pre-row",attrs:{"col":_vm.gridCol}},[_c('div',{staticClass:"separator--row"}),(_vm.data.layout !== 'thumb_podcast_episode')?_c('div',{staticClass:"separator--row u-hidden--until-s"}):_vm._e(),(_vm.data.layout !== 'thumb_podcast_episode')?_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"}):_vm._e(),(_vm.data.layout !== 'thumb_podcast_episode')?_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"}):_vm._e()]):_vm._e(),_c('Column',{attrs:{"size":_vm.data.layout === 'thumb_podcast_episode' ? { default: 12, s: 4 } : undefined}},[_c('Flex',{staticClass:"t-uppercase title-archive",attrs:{"justify":"space-between"}},[_c('Title',{attrs:{"data":{ value: group.name, size: 'l' }}}),(_vm.data.layout !== 'thumb_podcast_episode')?_c('Cta',{attrs:{"data":{
                  title: 'Vai allo show',
                  url: group.link,
                },"theme":"button"}}):_vm._e()],1)],1),_c('Column',{attrs:{"size":_vm.data.layout === 'thumb_podcast_episode' ? { default: 12, s: 8 } : undefined}},[(group.items.length > 0)?_c('Grid',{attrs:{"col":_vm.gridCol,"row-gap":_vm.data.layout === 'thumb_podcast_episode' ? 'l' : undefined}},_vm._l((group.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c(_vm.thumb(item.type),{tag:"component",attrs:{"data":item,"layout":_vm.data.layout}})],1)}),0):_vm._e()],1)],1)}),1)]:_vm._e(),_c('div',[(_vm.loadMore && _vm.items.length > 0)?_c('Spacer',{staticClass:"load-more t-center",attrs:{"top":"xxl","use-margin":""}},[_c('Cta',{style:({ '--border': 'var(--white)' }),attrs:{"data":{
            title: 'Carica altro',
            fn: _vm.requestOffset,
          },"theme":"rounded"}})],1):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }