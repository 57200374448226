<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="18"
      height="18"
      rx="9"
      fill="white"
    />
    <path
      d="M8.148 6.16L10.356 8.308H4.092V9.544H10.368L8.148 11.716L9.144 12.76L12.912 8.932L9.144 5.092L8.148 6.16Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  name: 'NextButton',
};
</script>
