import orderBy from 'lodash.orderby';

export default {
  menu: (state, getters) => (name) => state.menus[getters.lang][name],
  request:
    (state) => ({ type, params }) => {
      let res = null;
      const slugs = [];
      if (params.slug && params.slug.length > 0) {
        params.slug.forEach((slug) => {
          if (state[type][params.lang][slug]) slugs.push(slug);
        });
        return slugs.length > 0 ? slugs : null;
      }

      const request = state[type].requests.find((req) => {
        if (
          Object.keys(req.params).length
          === Object.keys(params).length
        ) {
          res = Object.keys(req.params).every(
            (key) => JSON.stringify(req.params[key])
              === JSON.stringify(params[key]),
          );
        }
        return res;
      });

      if (request) return request.data;
      return res;
    },
  singleBySlug:
    (state) => ({ type, slug, lang }) => state[type][lang][slug],
  options: (state) => state.options,
  postType: (state) => (key) => state.postTypes[key],
  postTypeFromArchive: (state) => (archiveSlug) => {
    let postType = null;
    Object.keys(state.postTypes).forEach((key) => {
      if (state.postTypes[key].has_archive === archiveSlug) {
        postType = state.postTypes[key];
      }
    });

    return postType;
  },
  getTag: (state) => (id) => Object.values(state.tags).find((tag) => tag.term_id === id),
  live: (state) => (state.options
    ? {
      audio: state.options.radio_audio_url,
      video: state.options.livestream_url,
    }
    : false),
  genre: (state) => ({
    session: [
      ...Object.values(state.tags).filter(
        (tag) => tag.parent === 0
          && tag.slug !== 'talk'
          && tag.slug !== 'other',
      ),
      state.tags.other,
    ],
    talk: Object.values(state.tags).filter(
      (tag) => tag.parent === 44,
    ),
    podcast: [],
  }),
  subgenreById: (state) => (id) => Object.values(state.tags).filter((tag) => tag.parent === id),
  scheduleOfDay: (state) => {
    const today = new Date(
      new Date().toLocaleString('en', {
        timeZone: 'Europe/Rome',
      }),
    )
      .toLocaleString('it-IT', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
      .split(',')[0]
      .replaceAll('/', '-');
    return state.schedules.default[today];
  },
  schedules: (state) => orderBy(Object.values(state.schedules.default), (o) => {
    const newDate = o.acf.data.split(', ')[1].split('.');
    const time = new Date(
      `20${newDate[2]}`,
      newDate[1] - 1,
      newDate[0],
    );
    return time;
  }),
};
