export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPost: (state) => state.currentPost,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  overlay: (state) => state.overlay,
  singleOverlay: (state) => state.singleOverlay,
  overlayStatus: (state) => (id) => state.overlay === id,
  embed: (state) => state.embed,
  embedStatus: (state) => state.embed && state.embed.status,
  embedById: (state) => (id) => state.embed && state.embed.id === id,
  embedStatusById: (state) => (id) => (state.embed && state.embed.id === id ? state.embed.status : false),
  audio: (state) => state.audio,
  video: (state) => state.video,
  liveStatus: (state) => state.live,
  now: (state) => state.now,
  isMuted: (state) => state.muted,
  theme: (state) => (state.currentPost
    && state.currentPost.acf
    && state.currentPost.acf.page_theme
    ? state.currentPost.acf.page_theme
    : state.currentPost && state.currentPost.type === 'galaxy_express'
      ? 'light'
      : false),
};
