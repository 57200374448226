var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{staticClass:"thumb",attrs:{"align":"start","row-gap":"xs"}},[_c('Column',{attrs:{"size":{ default: 12, s: 4 }}},[_c('router-link',{staticClass:"thumb-media",attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[(_vm.data.gds_featured_image)?_c('Figure',{attrs:{"data":{
          ..._vm.data.gds_featured_image,
          ratio: 1,
          align: _vm.data.acf.align_cover,
        },"disable-caption":""}}):_vm._e()],1)],1),_c('Column',{staticClass:"thumb-content",attrs:{"size":{ default: 12, s: 8 }}},[(!_vm.$mq.isMobile)?_c('div',{staticClass:"pre-row"},[_c('div',{staticClass:"separator--row"})]):_vm._e(),_c('div',{staticClass:"thumb-heading"},[_c('router-link',{staticClass:"title-link",attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{ value: _vm.title, size: 'l' }}})],1),_c('Flex',{staticClass:"artist",attrs:{"align":"baseline","nowrap":""}},[(
            _vm.data.acf.resident_guest_programma &&
              _vm.data.acf.resident_guest_programma[0]
          )?_c('router-link',{attrs:{"to":_vm.$relativeUrl(_vm.data.acf.resident_guest_programma[0].link)}},[_c('Title',{attrs:{"data":{
              value: _vm.data.acf.resident_guest_programma[0].title.rendered,
              size: 'm',
            }}})],1):(_vm.data.acf.subtitle)?_c('Title',{attrs:{"data":{ value: _vm.data.acf.subtitle, size: 'm' }}}):_vm._e()],1)],1),(_vm.data.gds_taxonomies.tags)?_c('div',{staticClass:"thumb-tags"},[_c('Tags',{attrs:{"data":_vm.data.gds_taxonomies.tags,"lite":""}})],1):_vm._e(),(_vm.data.content)?_c('RichText',{staticClass:"thumb-richtext",attrs:{"data":{ value: _vm.data.content.rendered, size: 's' }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }