<template>
  <div class="search">
    <Grid
      col="12"
      row-gap="0"
    >
      <div class="search-header">
        <Flex
          class="search-input bar"
          align="center"
        >
          <input
            v-model="request.s"
            type="text"
            name="search"
            placeholder="Cerca qualcosa..."
            spellcheck="false"
            @input="checkFilters"
          >
        </Flex>

        <!-- <Flex
          class="bar t-uppercase t-center"
          align="center"
          justify="center"
        >
          <Cta
            :data="{ title: showFilters ? 'Chiudi' : 'Esplora', fn: toggleFilters }"
            theme="button"
          />
        </Flex> -->

        <transition
          name="accordion"
          @enter="start"
          @after-enter="end"
          @before-leave="start"
          @after-leave="end"
        >
          <Grid
            v-show="showFilters"
            ref="discover"
            col="12"
            row-gap="l"
            class="discover"
          >
            <Spacer
              class="t-center t-uppercase"
              x="xs"
              bottom="xs"
              top="l"
            >
              <Grid
                col="12"
                row-gap="xs"
              >
                <Label :data="{ value: 'Categoria', size: 'xxs' }" />
                <Flex
                  justify="center"
                  gap
                >
                  <Flex
                    v-for="category in categories"
                    :key="category.slug"
                    align="center"
                    justify="center"
                    :class="[
                      'show-category',
                      request.category === category.slug ? 'show-category-active' : false,
                    ]"
                    @click.native="setShowCategory(category.slug)"
                  >
                    <Label :data="{ value: category.name, size: 'xs' }" />
                  </Flex>
                </Flex>
              </Grid>
            </Spacer>
            <Spacer
              x="xs"
              top="xs"
              bottom="l"
              class="t-center"
            >
              <Grid col="4">
                <div
                  v-for="filter in filters"
                  :key="filter.id"
                  :class="['filter', filter.options.length === 0 ? 'not-active' : false]"
                >
                  <Label :data="{ value: filter.name, size: 'xxs' }" />
                  <div class="select-wrapper">
                    <Select
                      v-if="filter.options.length > 0"
                      :key="
                        filter.slug === 'subgenre'
                          ? request.genre
                          : filter.slug === 'genre'
                            ? request.category
                            : filter.slug
                      "
                      :options="filter.options"
                      :map="filter.map"
                      :callback="filter.fn"
                      :initial-slide="filter.slug !== 'year' ? initialSlide(filter) : 0"
                    />
                  </div>
                </div>
              </Grid>
            </Spacer>
          </Grid>
        </transition>

        <div
          v-show="showFilters"
          class="separator--row separator--row--light"
        />
      </div>

      <div
        ref="results"
        class="results"
      >
        <Spacer
          x="xs"
          bottom="xs"
          top="xxs"
        >
          <div v-if="results && results.length === 0 && !found">
            Nessun risultato
          </div>
          <div v-else-if="results && results.length > 0">
            <Spacer
              bottom="xs"
              use-margin
            >
              <Label :data="{ value: 'Risultati', size: 'xxs' }" />
            </Spacer>
            <Grid
              col="12"
              row-gap="xs"
            >
              <ThumbResult
                v-for="thumb in results"
                :key="thumb.id"
                :data="thumb"
              />
            </Grid>
          </div>
          <div v-else>
            Sto cercando
          </div>
        </Spacer>

        <div
          v-if="totalResults && results && results.length > 0 && searchUrl"
          class="results-total"
        >
          <Cta
            :data="{
              title: `Visualizza tutti (${totalResults})`,
              url: searchUrl,
            }"
            theme="rounded-full"
          />
        </div>
      </div>
    </Grid>
  </div>
</template>

<script>
import axios from 'axios';
// import random from 'lodash.random';
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';
import Select from '@/components/form/custom/select';

import ThumbResult from '@/components/thumb/result';

export default {
  name: 'SearchInput',
  components: {
    Cta,
    Label,
    Select,
    ThumbResult,
  },
  data() {
    return {
      cancelToken: null,
      found: false,
      results: null,
      prevRequest: null,
      showFilters: true,
      totalResults: false,
      params: {},
      request: {
        s: '',
        category: undefined,
        year: 'all',
        genre: 'all',
        subgenre: undefined,
      },
      input: '',
      categories: [
        {
          id: 425,
          slug: 'session',
          name: 'Music',
        },
        {
          id: 426,
          slug: 'talk',
          name: 'Talks',
        },
        // {
        //   id: 427,
        //   slug: 'podcast',
        //   name: 'Podcast',
        // },
      ],
      filters: [
        {
          id: 0,
          slug: 'year',
          name: 'Anno',
          options: [],
          fn: this.setYear,
        },
        {
          id: 1,
          slug: 'genre',
          name: 'Genere',
          options: [],
          map: ['term_id', 'name'],
          fn: this.setGenre,
        },
        {
          id: 2,
          slug: 'subgenre',
          name: 'Sottogenere',
          options: [],
          map: ['term_id', 'name'],
          fn: this.setSubGenre,
        },
      ],
      showCategory: null,
    };
  },
  computed: {
    ...mapGetters(['genre', 'subgenreById', 'getTag']),
    searchUrl() {
      const {
        s, category, year, genre, subgenre,
      } = this.request;
      const query = s && s !== ''
        ? {
          s,
          page: 1,
        }
        : {
          category,
          year,
          genre,
          subgenre,
          page: 1,
        };
      return {
        name: 'Search',
        query,
      };
    },
  },
  watch: {
    request: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setRequest();
        });
      },
    },
  },
  created() {
    this.filters[0].options = this.years();
  },
  mounted() {
    this.setRequest();

    this.$bus.$on('openSearch', this.openSearchByUrl);

    // this.$refs.results.addEventListener('mousewheel', this.checkScroll);
    // this.$refs.results.addEventListener('touchmove', this.checkScroll);
  },
  beforeDestroy() {
    // this.$refs.results.removeEventListener('touchmove', this.checkScroll);
    // this.$refs.results.removeEventListener('mousewheel', this.checkScroll);
  },
  methods: {
    async setRequest() {
      if (this.request.s && this.request.s !== '') {
        this.params = {
          search: this.request.s,
          type: 'post',
          subtype: 'any',
          per_page: 12,
        };
      } else {
        if (this.request.subgenre === 'all') {
          this.request.subgenre = [
            this.request.genre,
            ...this.subgenreById(this.request.genre).map((subgenre) => subgenre.term_id),
          ];
        }

        this.params = {
          year: this.request.year !== 'all' ? this.request.year : undefined,
          tags: this.request.subgenre
            ? this.request.subgenre
            : this.request.genre && this.request.genre !== 'all'
              ? this.request.genre
              : undefined,
          show_category: this.request.category,
          type: 'post',
          subtype: 'episode',
          per_page: 12,
          discover_search: this.request.category ? true : undefined,
          // is_random:
          //   this.request.genre === 'all'
          //   && this.request.year === 'all'
          //   && !this.request.category
          //     ? true
          //     : undefined,
        };
      }

      const parseParams = Buffer.from(JSON.stringify(this.params), 'binary').toString('base64');

      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }
      this.cancelToken = axios.CancelToken.source();

      if (parseParams !== this.prevRequest) {
        this.results = null;
        await this.$store
          .dispatch('getSearch', {
            params: this.params,
            cancelToken: this.cancelToken.token,
          })
          .then((response) => {
            this.totalResults = !this.params.is_random ? response.headers['x-wp-total'] : false;

            // Cambiare parametro
            if (this.searchUrl) {
              const gaParams = {
                category: this.request.category === 'session' ? 'music' : this.request.category,
                year: this.params.year,
                genre:
                  this.request.genre && this.request.genre !== 'all'
                    ? this.getTag(this.request.genre).name
                    : undefined,
                subgenre:
                  this.request.subgenre && typeof this.request.subgenre !== 'object'
                    ? this.getTag(this.request.subgenre).name
                    : undefined,
                search: this.request.s,
              };
              if (window.gtag) {
                // TODO dispatch sendRequest {fn: 'GAsearch', 'params + nonce'}
                window.gtag('get', 'G-KD15B5DT3K', 'client_id', (client_id) => {
                  fetch(
                    'https://www.google-analytics.com/mp/collect?measurement_id=G-KD15B5DT3K&api_secret=KHye3HMqQgiSQJYV73NFWg',
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        client_id,
                        events: [
                          {
                            name: 'view_search_results',
                            params: gaParams,
                          },
                        ],
                      }),
                    },
                  );
                });
              }
            }

            if (response.data && response.data.length > 0) {
              this.found = true;
              this.results = response.data;
            } else {
              this.found = false;
              this.results = [];
            }
          });
      }

      this.prevRequest = parseParams;
    },
    years() {
      const years = [
        {
          id: 'all',
          value: 'Qualsiasi',
        },
      ];
      const to = new Date(
        new Date().toLocaleString('en', {
          timeZone: 'Europe/Rome',
        }),
      ).getFullYear();
      const from = 2017;

      for (let index = 0; index <= to - from; index += 1) {
        years.push({
          id: to - index,
          value: (to - index).toString(),
        });
      }
      return years;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
      if (this.showFilters) {
        this.setRequest();
        this.request.s = '';
      }
    },
    checkFilters() {
      if (this.request.s !== '') {
        this.showFilters = false;
        this.request.category = undefined;
        this.setYear('all');
        this.setGenre('all');
      } else {
        this.showFilters = true;
      }
    },
    setInput(value) {
      this.request.s = value;
    },
    setShowCategory(id) {
      this.request.category = id === this.request.category ? undefined : id;
      this.filters[1].options = [{ term_id: 'all', name: 'Qualsiasi' }, ...this.genre[id]];
      // Mandare evento GTAG: id
    },
    setYear(id) {
      this.request.year = id;
    },
    setGenre(id) {
      this.request.subgenre = undefined;
      this.request.genre = id;
      if (id !== 'all' && this.request.category === 'session') {
        this.filters[2].options = [
          {
            term_id: 'all',
            name: 'Tutti',
          },
          ...this.subgenreById(id),
        ];
      } else {
        this.filters[2].options = [];
      }
    },
    setSubGenre(id) {
      this.request.subgenre = id;
    },
    initialSlide() {
      // return random(0, filter.options.length - 1);
      return 0;
    },
    openSearchByUrl(type) {
      this.showFilters = true;
      this.request.category = type;
    },
    checkScroll(e) {
      if (e && this.results.length > 0 && this.showFilters) this.showFilters = false;
    },
    start(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background: var(--white);
  overflow-y: auto;
  height: 100%;

  &-header {
    background: var(--white);
    // position: sticky;
    position: relative;
    top: 0;
    z-index: 1;
  }

  &-input {
    background: var(--grey-l);
    border-bottom: 1px solid var(--black);

    input {
      position: relative;
      line-height: 25px;
      transform: translateY(-1px);
    }
  }

  .show-category {
    width: 100px;
    height: 100px;
    border: 1px solid var(--black);
    border-radius: 100%;
    cursor: pointer;

    &-active {
      background: var(--black);
      color: var(--white);
    }

    @include mq($and: $hover) {
      &:hover:not(.show-category-active) {
        background: var(--grey);
      }
    }
  }

  .filter {
    &.not-active {
      color: var(--grey);
    }

    .select-wrapper {
      position: relative;
      height: 150px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: currentColor;
        left: 0;
        top: 50%;
        pointer-events: none;
      }

      &::before {
        transform: translateY(-15px);
      }
      &::after {
        transform: translateY(15px);
      }
    }
  }

  .results {
    &-total {
      bottom: env(safe-area-inset-bottom);
      width: 100%;
      background: var(--white);
      padding: var(--spacer-xxs);
      text-align: center;
    }
  }
}
</style>
