<template>
  <Flex
    class="artist"
    align="baseline"
  >
    <router-link
      v-if="
        data.acf.show &&
          data.acf.show.acf &&
          data.acf.show.acf.resident_guest_programma &&
          data.acf.show.acf.resident_guest_programma[0]
      "
      :to="$relativeUrl(data.acf.show.acf.resident_guest_programma[0].link)"
    >
      <Title
        :data="{
          value: data.acf.show.acf.resident_guest_programma[0].title.rendered,
          size,
        }"
        class="t-ellipsis "
      />
    </router-link>

    <div v-if="data.acf.guest && data.acf.guest.length > 0">
      <span v-html="' w/ '" />

      <span
        v-for="(guest, index) in data.acf.guest"
        :key="guest.id"
        class="guest"
      >
        <span
          v-if="index > 0 && index !== data.acf.guest.length"
          v-html="', '"
        />
        <router-link
          :to="$relativeUrl(guest.link)"
          v-html="guest.title.rendered"
        />
      </span>
    </div>

    <Title
      v-else-if="
        data.acf.show && data.acf.show.acf && data.acf.show.acf.subtitle
      "
      :data="{ value: data.acf.show.acf.subtitle, size }"
    />
  </Flex>
</template>

<script>
import Title from '@/components/typo/title';

export default {
  name: 'Artist',
  components: {
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 's',
    },
  },
};
</script>

<style lang="scss" scoped>
.artist {
  & > * {
    flex: 0 1 auto;
    min-width: 0;
  }
}
</style>
