<template>
  <main>
    <div class="special-hero spacer--padding--b--xs">
      <component
        :is="getComponent(block)"
        v-for="(block, index) in firstTwoSection"
        :id="block && block.id ? block.id : undefined"
        :key="index"
        :class="[]"
        :data="block"
      />
    </div>
    <div class="spacer--padding--t--l">
      <component
        :is="getComponent(block)"
        v-for="(block, index) in sections"
        :id="block && block.id ? block.id : undefined"
        :key="index"
        :class="[
          theme !== 'about'
            ? block.theme &&
              (block.theme === 'hero' || block.theme === 'banner') &&
              block.id !== 'studio-hero'
              ? block.theme !== 'banner'
                ? 'spacer--padding--b--xxl'
                : 'spacer--padding--b--l'
              : block.acf_fc_layout === 'archive' || block.id === 'studio-hero'
                ? undefined
                : 'spacer--padding--b--l'
            : 'spacer--padding--t--s spacer--padding--t-s--xxl',
        ]"
        :data="block"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import components from '@/assets/js/components';
import data from '@/mixins/data';

export default {
  name: 'Page',
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  computed: {
    ...mapGetters(['liveStatus', 'theme']),
    checkArchiveSection() {
      return (
        this.post.acf
        && this.post.acf.section
        && this.post.acf.section[0]
        && this.post.acf.section[0].layout === 'thumb_archive'
      );
    },
    firstTwoSection() {
      return this.post.acf.section.slice(0, 2);
    },
    sections() {
      return this.post.acf.section.slice(2);
    },
  },
  methods: {
    getComponent(block) {
      if (block.id === 'studio-hero') {
        return components.get('studio-hero');
      }
      return components.get(block.acf_fc_layout);
    },
  },
};
</script>

<style lang="scss">
[data-theme="about"] {
  main {
    position: relative;
    min-height: 700px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding-bottom: var(--spacer-xs);

    @include mq(s) {
      min-height: calc(var(--vh) * 100 - 20px);
    }

    .label {
      text-transform: uppercase;
    }
  }
}

.special-hero {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh) * 100);
}
</style>
