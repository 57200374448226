<template>
  <Grid
    class="thumb"
    align="start"
    row-gap="xxs"
  >
    <Column
      class="separator--row"
      size="12"
    />
    <Column size="2">
      <ThumbPlayer
        :data="data"
        podcast
        :title="title"
      />
    </Column>
    <Column
      class="thumb-content"
      size="10"
    >
      <div
        v-if="single"
        class="pre-row"
      >
        <div class="separator--row" />
      </div>

      <div class="thumb-heading">
        <Label
          v-if="data.acf.data"
          class="meta"
          :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
        />

        <Title :data="{ value: title, size: 'm' }" />

        <Flex
          class="artist"
          align="baseline"
          nowrap
        >
          <div v-if="data.acf.guest && data.acf.guest.length > 0">
            <span v-html="' w/ '" />

            <span
              v-for="(guest, index) in data.acf.guest"
              :key="guest.id"
              class="guest"
            >
              <span
                v-if="index > 0 && index !== data.acf.guest.length"
                v-html="', '"
              />
              <router-link
                :to="$relativeUrl(guest.link)"
                v-html="guest.title.rendered"
              />
            </span>
          </div>

          <Title
            v-else-if="data.acf.subtitle"
            :data="{ value: data.acf.subtitle, size: 's' }"
          />
        </Flex>
      </div>

      <RichText
        v-if="data.content"
        class="thumb-richtext"
        :data="{ value: data.content.rendered, size: 's' }"
      />
    </Column>
  </Grid>
</template>

<script>
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import ThumbPlayer from '@/components/thumb/components/thumb-player.vue';

export default {
  name: 'ThumbSinglePodcast',
  components: {
    Title,
    RichText,
    Label,
    ThumbPlayer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  .meta {
    margin-bottom: var(--spacer-xs);
  }
  .title {
    display: block;
    margin-bottom: var(--spacer-s);
  }
  .artist {
    margin-top: var(--spacer-xxs);
  }
  &-tags {
    margin-bottom: var(--spacer-s);
  }

  &-content {
    position: relative;
    min-height: 100%;
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
  }

  &-richtext {
    @include mq(m) {
      max-width: 80%;
    }
  }

  &-links {
    align-self: end;
  }

  .thumb-player {
    margin-top: 28px;

    @include mq(m) {
      margin-top: 22px;
    }
  }
}
</style>
