/* eslint-disable no-undef */

import axios from 'axios';

let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

const urlWp = `${url}/wp-json/`;

const ajax = axios.create({
  baseURL: urlWp,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

ajax.interceptors.request.use((config) => {
  if (window.location.href.includes('/preview/')) {
    config.headers['X-WP-Nonce'] = __VUE_WORDPRESS__.state.session.nonce;
  }
  return config;
});

export default ajax;
