<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="18"
      y="18"
      width="18"
      height="18"
      rx="9"
      transform="rotate(-180 18 18)"
      fill="white"
    />
    <path
      d="M9.852 11.84L7.644 9.692L13.908 9.692L13.908 8.456L7.632 8.456L9.852 6.284L8.856 5.24L5.088 9.068L8.856 12.908L9.852 11.84Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'PrevButton',
};
</script>
