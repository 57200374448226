/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Cookies from 'js-cookie';

import SwitchComponent from '@/views/SwitchComponent.vue';

import Single from '@/views/Single.vue';

import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set('radio', Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n, password } = __VUE_WORDPRESS__.state;

const archiveSlugs = [];
// let archivePodcast = null;
Object.keys(postTypes).forEach((key) => {
  if (postTypes[key].has_archive) {
    if (key === 'podcast') {
      // archivePodcast = postTypes[key].has_archive;
    } else {
      archiveSlugs.push(postTypes[key].has_archive);
    }
  }
});
const { name: siteTitle } = __VUE_WORDPRESS__.state.site;

store.commit('ADD_LANGUAGES', ['default']);

const baseRoutes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Index',
    component: SwitchComponent,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: SwitchComponent,
    meta: {
      component: 'search',
    },
  },
  {
    path: '/:slug',
    name: 'Page',
    component: SwitchComponent,
  },
  {
    path: '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
  },
];

const routes = baseRoutes;

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'post') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name:
        singles.get(key).name === 'Single'
          ? `${singles.get(key).name}-${key}`
          : singles.get(key).name,
      component: SwitchComponent,
      meta: {
        type: postTypes[key].rest_base,
        component: 'single',
        hideHeader: true,
        hideFooter: true,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  document.body.classList.add('app-avoid');

  const isOverlay = from && from.name && to.name === 'SingleEpisode';

  if (from.path !== to.path) {
    store.commit('SET_CURRENT_POST', null);
  }

  store.commit('SET_OVERLAY', false);
  store.commit('SET_SINGLE_OVERLAY', false);

  if (store.state.live === 'video' && to.name !== 'Index' && !isOverlay) {
    store.commit('TOGGLE_LIVE', false);
  }

  if (store.getters.live.video && to.name === 'Index' && store.state.live !== 'audio') {
    store.commit('TOGGLE_LIVE', 'video');
  }

  if (
    to.meta.customView
    || to.name === '404'
    || to.name === 'Search'
    || to.name === 'Preview'
    || to.name === 'Archive'
    || to.name === 'ArchivePodcast'
  ) {
    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }
    document.body.classList.remove('app-avoid');
    document.body.removeAttribute('data-theme');

    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang ? to.params.lang : i18n ? i18n.default : 'default';

  store.commit('SET_LANG', lang);

  if (password) {
    Cookies.set(`studio-raheem-${slug}`, password, { expires: 365 });
  }

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
    password: Cookies.get(`studio-raheem-${slug}`) || password,
  };

  const promises = [];

  promises.push(store.dispatch('getSingleBySlug', request));

  Promise.all(promises).then((res) => {
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    const page = res[0];

    if (page && slug) {
      if (to.path === '/') {
        document.title = `${page.title?.rendered}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');

        let title = typeof page.title === 'string' ? page.title : page.title.rendered;
        if (
          page.acf.show
          && page.acf.show.title.rendered
          && !title.startsWith(page.acf.show.title.rendered)
        ) {
          title = `${page.acf.show.title.rendered} ${title}`;
        }

        p.innerHTML = title;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace('_', '-');
      }

      if (isOverlay) {
        store.commit('SET_OVERLAY', 'episode');
        store.commit('SET_SINGLE_OVERLAY', true);
        to.meta.overlay = true;
        to.meta.prevPath = from.path;
      }
      document.body.classList.remove('app-avoid');
      next();
    } else {
      document.body.classList.remove('app-avoid');
      router.push({ name: '404' });
    }
  });
});

export default router;
