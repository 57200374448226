<template>
  <component
    :is="component"
    :slug="slug"
    :type="type"
  />
</template>

<script>
import Page from '@/views/Page';
import Archive from '@/views/Archive';
// import ArchivePodcast from '@/views/ArchivePodcast';
import Search from '@/views/Search';
import Single from '@/views/Single';
import SingleEpisode from '@/views/SingleEpisode';
import SinglePodcast from '@/views/SinglePodcast';

export default {
  name: 'SwitchComponent',
  components: {
    Page,
    Archive,
    // ArchivePodcast,
    Search,
    Single,
    SingleEpisode,
    SinglePodcast,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      component:
      // (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name === 'ArchivePodcast') || (this.$route.meta.component === 'archive' && this.$route.name === 'ArchivePodcast') ? ArchivePodcast
          (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name === 'Archive') || this.$route.meta.component === 'archive' ? Archive
            : (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name === 'Search') || this.$route.meta.component === 'search' ? Search
              : (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name === 'SinglePodcast') || (this.$route.meta.component === 'single' && this.$route.name === 'SinglePodcast') ? SinglePodcast
                : (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name && this.$store.state.route.from.name.includes('Single')) || this.$route.meta.component === 'single' ? Single
                  : (this.$route.name === 'SingleEpisode' && this.$store.state.route.from && this.$store.state.route.from.name === 'SingleEpisode') || this.$route.meta.component === 'episode' ? SingleEpisode
                    : Page,
    };
  },
};
</script>

<style>

</style>
