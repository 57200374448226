<template>
  <component
    :is="component"
    v-if="data"
    :preview="data"
  />
</template>

<script>
import Page from '@/views/Page';
import Single from '@/views/Single';

export default {
  name: 'Preview',
  components: {
    Page,
    Single,
  },
  data() {
    return {
      component: this.$route.params.type === 'page' ? Page : Single,
      data: null,
    };
  },
  created() {
    const type = this.$route.params.type === 'post' ? 'posts' : this.$route.params.type === 'page' ? 'pages' : this.$route.params.type;
    this.$store.dispatch('getSingleById', { type, id: this.$route.params.id }).then((data) => {
      this.data = Array.isArray(data) ? data[0] : data;
    });
  },
};
</script>

<style>

</style>
