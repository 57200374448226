<template>
  <div class="video-player">
    <div
      v-if="data.embed && data.link"
      ref="player"
      class="plyr"
      v-html="data.link"
    />

    <Live
      v-if="data.embed && data.livestream"
      :data="{
        src: data.livestream,
        params: {
          enableInfoAndActivity: 'false',
          defaultDrawer: 'feed',
          autoPlay: 'false',
        },
        avoidScroll: true,
      }"
    />

    <video
      v-if="!data.embed && data.src"
      ref="player"
      class="plyr"
      playsinline
    >
      <source
        :src="data.src"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.caption && data.caption !== ''"
      :data="{ value: data.caption }"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Live from '@/components/media/live';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
    Live,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
    };
  },
  mounted() {
    this.plyr = new Plyr(this.$refs.player, {
      autoplay: false,
      controls: ['play', 'progress', 'mute'],
      fullscreen: false,
      storage: {
        enabled: false,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}
</style>
