var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
    'spacer',
    ..._vm.computedAll,
    ..._vm.computedX,
    ..._vm.computedY,
    ..._vm.computedTop,
    ..._vm.computedBottom,
    ..._vm.computedLeft,
    ..._vm.computedRight,
    _vm.template !== '' ? `spacer--${_vm.template}` : null,
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }