import Content from '@/components/blocks/content';
import List from '@/components/blocks/list';
import ContentList from '@/components/blocks/contentList';
import Archive from '@/components/blocks/archive';
import Accordion from '@/components/blocks/accordion';
import Images from '@/components/blocks/images';
import Carousel from '@/components/blocks/carousel';
import Gallery from '@/components/blocks/gallery';
import Video from '@/components/blocks/video';
import Newsletter from '@/components/blocks/newsletter';
import Custom from '@/components/blocks/custom';
import StudioHero from '@/components/blocks/custom/studioHero';

const components = new Map();

components.set('content', Content);
components.set('images', Images);
components.set('gallery', Gallery);
components.set('carousel', Carousel);
components.set('video', Video);
components.set('list', List);
components.set('content_list', ContentList);
components.set('newsletter', Newsletter);
components.set('accordion', Accordion);
components.set('placeholder', Custom);
components.set('archive', Archive);
components.set('studio-hero', StudioHero);

export default components;
