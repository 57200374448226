<template>
  <section>
    <Grid
      col="12"
      row-gap="xs"
    >
      <Grid
        v-for="(row, indexRow) in data.image_rows"
        :key="indexRow"
        row-gap="xs"
      >
        <Column
          v-for="(img, indexImg) in row.images"
          :key="indexImg"
          :size="size(row.images.length)"
        >
          <Figure :data="{...img, ratio: true}" />
        </Column>
      </Grid>
    </Grid>
  </section>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'Images',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    size(length) {
      if (length > 4) return '3';
      return (12 / length).toString();
    },
  },
};
</script>

<style>

</style>
