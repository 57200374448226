var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:[
    'thumb',
    `thumb--${_vm.layout}`,
    _vm.index === 0 || _vm.data.preview_thumb ? 'thumb--large' : false,
  ],attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[_c('div',{staticClass:"thumb-cover"},[(_vm.data.gds_featured_image)?_c('Figure',{attrs:{"data":{
        ..._vm.data.gds_featured_image,
        ratio: 0.5625,
        align:
          _vm.data.acf && _vm.data.acf.align_cover ? _vm.data.acf.align_cover : undefined,
      },"disable-caption":""}}):_vm._e()],1),_c('Spacer',{staticClass:"thumb-content",attrs:{"top":"xs"}},[_c('div',{staticClass:"thumb-content-inner"},[(_vm.data.date_format && _vm.data.type !== 'galaxy_express')?_c('Label',{staticClass:"meta",attrs:{"data":{ value: _vm.data.date_format, size: 'xxs', tag: 'time' }}}):_vm._e(),_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{ value: _vm.title, size: _vm.large && !_vm.$mq.isMobile ? 'xl' : 'l' }}}),(_vm.data.acf && _vm.data.acf.data)?_c('Label',{staticClass:"meta-event",attrs:{"data":{ value: _vm.data.acf.data, size: 'm', tag: 'time' }}}):_vm._e(),(_vm.content && !_vm.thumbProject && !_vm.thumbEvent)?_c('div',{staticClass:"excerpt"},[_c('Richtext',{class:[
            _vm.layout === 'thumb_archive_half'
              ? 'content-clamp content-clamp--4'
              : false,
          ],attrs:{"data":{ value: _vm.content, size: _vm.large && !_vm.$mq.isMobile ? 'm' : 's' }}})],1):_vm._e()],1),_c('Cta',{attrs:{"data":{
        title: 'Leggi di più',
        url: _vm.data.link,
        size: 's',
      },"theme":"button"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }