var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('Spacer',{attrs:{"top":_vm.liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'}}),_c('Spacer',{staticClass:"results",attrs:{"x":"xs","bottom":"xxl"}},[_c('Grid',{staticClass:"pre-row",attrs:{"col":{default: 12, s: 6, m: 3 }}},[_c('div',{staticClass:"separator--row"}),_c('div',{staticClass:"separator--row u-hidden--until-s"}),_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"}),_c('div',{staticClass:"separator--row u-hidden--until-s u-hidden--until-m"})]),_c('Flex',{key:JSON.stringify(_vm.$route.query),staticClass:"results-title",attrs:{"justify":"space-between"}},[_c('Title',{staticClass:"t-uppercase",attrs:{"data":{ value: _vm.title, size: 'l' }}}),_c('Cta',{attrs:{"data":{
          title: 'Nuova ricerca',
          fn: _vm.openSearch
        },"theme":"button"}})],1),(_vm.results && _vm.results.length > 0)?_c('div',[_c('Grid',{attrs:{"col":{ default: 12, s: 6, m: 3 }}},_vm._l((_vm.results),function(thumb){return _c('Thumb',{key:thumb.id,attrs:{"data":thumb,"theme":"default"}})}),1)],1):_vm._e(),(_vm.loadMore)?_c('Spacer',{staticClass:"load-more t-center",attrs:{"top":"xxl","use-margin":""}},[_c('Cta',{attrs:{"data":{
          title: 'Carica altro',
          fn: _vm.setOffset
        },"theme":"rounded"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }