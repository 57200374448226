var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-player"},[(_vm.data.embed && _vm.data.link)?_c('div',{ref:"player",staticClass:"plyr",domProps:{"innerHTML":_vm._s(_vm.data.link)}}):_vm._e(),(_vm.data.embed && _vm.data.livestream)?_c('Live',{attrs:{"data":{
      src: _vm.data.livestream,
      params: {
        enableInfoAndActivity: 'false',
        defaultDrawer: 'feed',
        autoPlay: 'false',
      },
      avoidScroll: true,
    }}}):_vm._e(),(!_vm.data.embed && _vm.data.src)?_c('video',{ref:"player",staticClass:"plyr",attrs:{"playsinline":""}},[_c('source',{attrs:{"src":_vm.data.src,"type":"video/mp4"}})]):_vm._e(),(_vm.data.caption && _vm.data.caption !== '')?_c('Richtext',{attrs:{"data":{ value: _vm.data.caption }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }