var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('Spacer',{attrs:{"top":_vm.liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'}}),_c('Spacer',{attrs:{"x":"xs"}},[_c('Spacer',{staticClass:"breadcrumbs",attrs:{"bottom":"xs"}},[_c('Cta',{attrs:{"data":{
          url: '/podcasts',
          title: 'Podcasts',
        }}})],1),_c('Grid',{staticClass:"thumb",attrs:{"align":"start","row-gap":"xs"}},[_c('Column',{attrs:{"size":{ default: 12, s: 4 }}},[(_vm.post.gds_featured_image)?_c('Figure',{attrs:{"data":{
            ..._vm.post.gds_featured_image,
            mobile: _vm.post.acf.featured_image_mobile,
            ratio: 1,
            align: _vm.post.acf.align_cover,
          },"disable-caption":""}}):_vm._e()],1),_c('Column',{staticClass:"thumb-content",attrs:{"size":{ default: 12, s: 8 }}},[(!_vm.$mq.isMobile)?_c('div',{staticClass:"pre-row"},[_c('div',{staticClass:"separator--row"})]):_vm._e(),_c('div',{staticClass:"thumb-heading"},[_c('div',{staticClass:"title-link"},[_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{ value: _vm.title, size: 'l' }}})],1),_c('Flex',{staticClass:"artist",attrs:{"align":"baseline","nowrap":""}},[(
                _vm.post.acf.resident_guest_programma &&
                  _vm.post.acf.resident_guest_programma[0]
              )?_c('router-link',{attrs:{"to":_vm.$relativeUrl(
                  _vm.post.acf.resident_guest_programma[0].link
                )}},[_c('Title',{attrs:{"data":{
                  value:
                    _vm.post.acf.resident_guest_programma[0].title
                      .rendered,
                  size: 'm',
                }}})],1):(_vm.post.acf.subtitle)?_c('Title',{attrs:{"data":{ value: _vm.post.acf.subtitle, size: 'm' }}}):_vm._e()],1)],1),(_vm.post.gds_taxonomies.tags)?_c('div',{staticClass:"thumb-tags"},[_c('Tags',{attrs:{"data":_vm.post.gds_taxonomies.tags}})],1):_vm._e(),(_vm.post.content)?_c('RichText',{staticClass:"thumb-richtext",attrs:{"data":{ value: _vm.post.content.rendered, size: 's' }}}):_vm._e(),(_vm.post.acf.link_contributor)?_c('div',{staticClass:"thumb-links"},[_c('Socials',{attrs:{"data":_vm.post.acf.link_contributor,"label":"Ascolta su"}})],1):_vm._e()],1)],1)],1),_c('Spacer',{attrs:{"top":"xxl"}}),_c('Archive',{attrs:{"data":{
      layout: 'thumb_podcast_episode',
      categories: `podcast:${_vm.post.id}`,
      infinite_scroll: false,
      post_type: 'episode',
      thumbs: false,
      groupBy: 'show',
      title: 'Episodi',
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }