<template>
  <div class="asteroid-about">
    <Asteroid :data="featuredImage.url" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Asteroid from '@/components/media/asteroid';

export default {
  name: 'AsteroidAbout',
  components: {
    Asteroid,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['now']),
    featuredImage() {
      if (this.data.img) {
        return this.data.img;
      }

      return {
        height: 450,
        mime_type: 'image/jpeg',
        subtype: 'jpeg',
        type: 'image',
        url: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        sizes: {
          xxl: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/img/placeholder.jpg`,
        },
        width: 800,
      };
      // const now = this.now && this.now.episode ? this.now.episode : false;
      // return now
      //   ? now.gds_featured_image
      //     ? now.gds_featured_image
      //     : now.acf.show && now.acf.show.gds_featured_image
      //       ? now.acf.show.gds_featured_image
      //       : now.acf.show
      //       && now.acf.show.acf.resident_guest_programma
      //       && now.acf.show.acf.resident_guest_programma[0]
      //       && now.acf.show.acf.resident_guest_programma[0].gds_featured_image
      //         ? now.acf.show.acf.resident_guest_programma[0].gds_featured_image
      //         : placeholder
      //   : placeholder;
    },
  },
};
</script>

<style lang="scss">
.asteroid-about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 0;

  div {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;

    @include mq($until: s) {
      top: 50%;
      height: 100vmin;
      transform: translateY(-50%);
    }
  }
}

[data-theme="about"] {
  main {
    &>*:not(.asteroid-about) {
      position: relative;
      z-index: 2;
      pointer-events: none;

      a {
        pointer-events: auto;
      }
    }
  }
}
</style>
