var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"calendar",attrs:{"tag":"section"}},[_c('Spacer',{attrs:{"bottom":"xl"}},[(
        _vm.data.title ||
          (_vm.data.text && !(_vm.schedules && _vm.schedules.length))
      )?_c('Content',{attrs:{"data":{
        title: _vm.data.title ? _vm.data.title : undefined,
        rich_text:
          !(_vm.schedules && _vm.schedules.length) > 0
            ? _vm.data.text
            : undefined,
        theme: 'hero',
      }}}):_vm._e()],1),_c('Spacer',{style:({ '--rows': _vm.hours.minutes.length }),attrs:{"x":"xs"}},[(_vm.schedules && _vm.schedules.length > 0)?_c('div',{staticClass:"calendar-wrapper"},[_c('Grid',{staticClass:"hours typo--xs",attrs:{"grid":"1","row-gap":"xs"}},_vm._l((_vm.hours.fixed),function(hour){return _c('div',{key:hour,staticClass:"hour"},[_c('span',[_vm._v(_vm._s(_vm.hourFormatted(hour)))])])}),0),_c('Grid',{staticClass:"schedule-wrapper",attrs:{"col":{ default: 12, m: _vm.schedulesLength },"row-gap":"xs"}},[(_vm.$mq.isMobile)?_c('div',{ref:"headingWrapper",staticClass:"heading"},[_c('div',{ref:"slider",staticClass:"heading-wrapper swiper-container"},[_c('Flex',{staticClass:"swiper-wrapper",attrs:{"nowrap":""}},_vm._l((_vm.schedules),function(schedule,scheduleIndex){return _c('div',{key:scheduleIndex,class:[
                  'swiper-slide',
                  'heading-title',
                  _vm.scheduleVisible === scheduleIndex
                    ? 'heading-title--active'
                    : false,
                ]},[_c('Cta',{attrs:{"data":{
                    title: schedule.acf.data,
                    size: 'xs',
                    fn: () => _vm.setScheduleVisible(scheduleIndex),
                  }}})],1)}),0)],1)]):_vm._e(),_vm._l((_vm.schedules),function(schedule,scheduleIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.$mq.isMobile || _vm.scheduleVisible === scheduleIndex
          ),expression:"\n            !$mq.isMobile || scheduleVisible === scheduleIndex\n          "}],key:schedule.id,staticClass:"schedule"},[_c('div',{staticClass:"heading u-hidden--until-m"},[_c('div',{staticClass:"heading-wrapper"},[_c('div',{staticClass:"separator--row"}),_c('Label',{staticClass:"heading-label",attrs:{"data":{ value: schedule.acf.data, size: 'xs' }}})],1)]),_c('Grid',{staticClass:"schedule",attrs:{"grid":"1","row-gap":"xs"}},_vm._l((schedule.acf.schedule),function(session,index){return _c('div',{key:index,class:[
                'calendar-card',
                _vm.isLive(schedule.acf.data, index)
                  ? 'calendar-card--active'
                  : false,
              ],style:(_vm.placeInGrid(session.ora_inizio, session.ora_fine))},[_c(session.episode ? 'router-link' : 'div',{tag:"component",attrs:{"to":session.episode
                    ? _vm.$relativeUrl(session.episode.link)
                    : undefined}},[_c('Title',{attrs:{"data":{ value: session.titolo, size: 's' }}})],1)],1)}),0)],1)})],2)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }