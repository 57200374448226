var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['thumb', 'thumb-result']},[_c('router-link',{staticClass:"thumb-figure",attrs:{"to":_vm.$relativeUrl(_vm.data.url)}},[(_vm.featuredImage)?_c('Figure',{attrs:{"data":{
        ..._vm.featuredImage,
        ratio: _vm.data.subtype === 'podcast' ? 1 : 0.84,
        align: _vm.alignImage,
      },"disable-caption":""}}):_vm._e()],1),_c('div',{staticClass:"thumb-content"},[(_vm.data.acf.data && _vm.data.subtype === 'episode')?_c('Label',{staticClass:"meta",attrs:{"data":{ value: _vm.data.acf.data, size: 'xxs', tag: 'time' }}}):_vm._e(),(_vm.data.subtype !== 'episode')?_c('Label',{staticClass:"subtype",attrs:{"data":{ value: _vm.data.subtype, size: 'xxs' }}}):_vm._e(),_c('router-link',{class:[
        'title-link',
        _vm.data.subtype !== 'episode' ? 'title-link-margin' : false,
      ],attrs:{"to":_vm.$relativeUrl(_vm.data.url)}},[_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{ value: _vm.title, size: 's' }}})],1),_c('Artist',{staticClass:"typo--xs",attrs:{"data":_vm.data,"size":"xs"}}),(_vm.data.gds_taxonomies.tags && _vm.data.subtype === 'episode')?_c('div',{staticClass:"tags"},[_c('Tags',{attrs:{"data":_vm.data.gds_taxonomies.tags,"theme":"dark","lite":""}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }