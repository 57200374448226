<template>
  <main>
    <Spacer
      :top="
        liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'
      "
    />
    <Spacer x="xs">
      <Spacer
        bottom="xs"
        class="breadcrumbs"
      >
        <Cta
          :data="{
            url: '/podcasts',
            title: 'Podcasts',
          }"
        />
      </Spacer>
      <Grid
        class="thumb"
        align="start"
        row-gap="xs"
      >
        <Column :size="{ default: 12, s: 4 }">
          <Figure
            v-if="post.gds_featured_image"
            :data="{
              ...post.gds_featured_image,
              mobile: post.acf.featured_image_mobile,
              ratio: 1,
              align: post.acf.align_cover,
            }"
            disable-caption
          />
        </Column>

        <Column
          class="thumb-content"
          :size="{ default: 12, s: 8 }"
        >
          <div
            v-if="!$mq.isMobile"
            class="pre-row"
          >
            <div class="separator--row" />
          </div>

          <div class="thumb-heading">
            <div class="title-link">
              <Title
                :data="{ value: title, size: 'l' }"
                class="t-ellipsis"
              />
            </div>

            <Flex
              class="artist"
              align="baseline"
              nowrap
            >
              <router-link
                v-if="
                  post.acf.resident_guest_programma &&
                    post.acf.resident_guest_programma[0]
                "
                :to="
                  $relativeUrl(
                    post.acf.resident_guest_programma[0].link
                  )
                "
              >
                <Title
                  :data="{
                    value:
                      post.acf.resident_guest_programma[0].title
                        .rendered,
                    size: 'm',
                  }"
                />
              </router-link>

              <Title
                v-else-if="post.acf.subtitle"
                :data="{ value: post.acf.subtitle, size: 'm' }"
              />
            </Flex>
          </div>

          <div
            v-if="post.gds_taxonomies.tags"
            class="thumb-tags"
          >
            <Tags :data="post.gds_taxonomies.tags" />
          </div>

          <RichText
            v-if="post.content"
            class="thumb-richtext"
            :data="{ value: post.content.rendered, size: 's' }"
          />

          <div
            v-if="post.acf.link_contributor"
            class="thumb-links"
          >
            <Socials
              :data="post.acf.link_contributor"
              label="Ascolta su"
            />
          </div>
        </Column>
      </Grid>
    </Spacer>
    <Spacer top="xxl" />
    <Archive
      :data="{
        layout: 'thumb_podcast_episode',
        categories: `podcast:${post.id}`,
        infinite_scroll: false,
        post_type: 'episode',
        thumbs: false,
        groupBy: 'show',
        title: 'Episodi',
      }"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Figure from '@/components/media/figure';
import Cta from '@/components/typo/cta';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Tags from '@/components/typo/tags';

import Socials from '@/components/thumb/components/socials';
import Archive from '@/components/blocks/archive';

import data from '@/mixins/data';

export default {
  name: 'SinglePodcast',
  components: {
    Figure,
    Title,
    Cta,
    RichText,
    Tags,
    Socials,
    Archive,
  },
  mixins: [data],
  computed: {
    ...mapGetters(['liveStatus']),
    title() {
      const title = typeof this.post.title === 'string'
        ? this.post.title
        : this.post.title.rendered;
      if (
        this.post.acf.show
        && this.post.acf.show.title.rendered
        && !title.startsWith(this.post.acf.show.title.rendered)
      ) {
        return `${this.post.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  text-transform: uppercase;
  color: var(--grey-xd);
  @extend %typo--xs;
}

.thumb {
  .title-link {
    display: block;

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .artist {
    // margin-top: var(--spacer-xxs);
  }
  &-tags {
    margin-bottom: var(--spacer-s);
  }

  &-content {
    position: relative;
    min-height: 100%;
    display: grid;
    grid-template-rows: repeat(3, auto) 1fr;
  }

  &-richtext {
    @include mq(m) {
      max-width: 80%;
    }
  }

  &-links {
    margin-top: var(--spacer-s);
    align-self: end;
  }

  &-heading {
    margin-bottom: var(--spacer-s);
    min-height: 60px;
    @include mq(m) {
      margin-bottom: 0;
      min-height: 120px;
    }
  }
}
</style>
