var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"special-hero spacer--padding--b--xs"},_vm._l((_vm.firstTwoSection),function(block,index){return _c(_vm.getComponent(block),{key:index,tag:"component",class:[],attrs:{"id":block && block.id ? block.id : undefined,"data":block}})}),1),_c('div',{staticClass:"spacer--padding--t--l"},_vm._l((_vm.sections),function(block,index){return _c(_vm.getComponent(block),{key:index,tag:"component",class:[
        _vm.theme !== 'about'
          ? block.theme &&
            (block.theme === 'hero' || block.theme === 'banner') &&
            block.id !== 'studio-hero'
            ? block.theme !== 'banner'
              ? 'spacer--padding--b--xxl'
              : 'spacer--padding--b--l'
            : block.acf_fc_layout === 'archive' || block.id === 'studio-hero'
              ? undefined
              : 'spacer--padding--b--l'
          : 'spacer--padding--t--s spacer--padding--t-s--xxl',
      ],attrs:{"id":block && block.id ? block.id : undefined,"data":block}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }