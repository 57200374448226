<template>
  <div
    id="app"
    :class="[embed ? 'is-embed-active' : false]"
  >
    <AppOverlay />

    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="filteredPath.path" />
    </transition>

    <AppFooter />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppOverlay from '@/components/ui/overlay';
import AppFooter from '@/components/ui/footer';

export default {
  name: 'App',
  components: {
    AppOverlay,
    AppFooter,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters([
      'currentPost',
      'options',
      'overlay',
      'singleOverlay',
      'live',
      'audio',
      'video',
      'liveStatus',
      'now',
      'embed',
    ]),
    filteredPath() {
      let route = this.$route;
      if (
        this.$store.state.route.from
        && this.$store.state.route.from.name
        && this.$route.name === 'SingleEpisode'
      ) {
        route = this.$store.state.route.from;
      }
      return route;
    },
  },
  created() {
    medusa.init();

    // if (this.$workbox) {
    //   this.$workbox.addEventListener('waiting', () => {
    //     this.showUpgradeUI = true;
    //   });
    // }
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace(
        '8888',
        '3000',
      );
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 100),
    );

    this.setBottomNl(true);

    this.$bus.$on('iubendaReady', () => this.setBottomNl(true));
    this.$bus.$on('iubendaClose', () => this.setBottomNl(false));
    this.$bus.$on('windowResized', () => this.setBottomNl(true));

    if (this.$mq.isSafari || this.$mq.isIOS) {
      document.body.classList.add('safari');
    }
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    setBottomNl(flag) {
      const cookie = document.querySelector('#iubenda-cs-banner');
      let height = '0px';
      if (cookie && flag) {
        const { clientHeight } = cookie;
        height = `${clientHeight}px`;
      }
      document.documentElement.style.setProperty(
        '--bottom-nl',
        height,
      );
    },
    // async accept() {
    //   this.showUpgradeUI = false;
    //   await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    // },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.app-loaded {
  #loader {
    pointer-events: none;
    img {
      opacity: 0;
    }
    // display: none;
  }
}

.app-avoid {
  cursor: progress;

  * {
    pointer-events: none !important;
  }
}

main {
  @include mq(s) {
    min-height: calc(var(--vh) * 100 - 20px);
  }
}

a.wrapper-cta {
  @include mq($and: $hover) {
    transition: color 0.2s $ease-custom;
    &:hover {
      color: var(--grey-d);
    }
  }
}
</style>
