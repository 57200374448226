<template>
  <div
    class="select swiper-container"
  >
    <div class="swiper-wrapper">
      <div
        v-for="option in options"
        :key="option[id]"
        :class="['swiper-slide', 'option']"
        :data-id="option[id]"
        v-html="option[value]"
      />
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.min.css';
import Swiper, { Mousewheel } from 'swiper';

Swiper.use([Mousewheel]);

export default {
  name: 'Select',
  props: {
    options: {
      type: Array,
      default: () => {},
    },
    map: {
      type: Array,
      default: () => {},
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  computed: {
    id() {
      return this.map ? this.map[0] : 'id';
    },
    value() {
      return this.map ? this.map[1] : 'value';
    },
  },
  mounted() {
    this.slider = new Swiper(this.$el, {
      direction: 'vertical',
      initialSlide: this.initialSlide,
      centeredSlides: true,
      height: 150,
      slidesPerView: 5,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      freeModeSticky: true,
      mousewheel: true,
      on: {
        init: () => {
          this.callback(this.options[this.initialSlide][this.id]);
        },
      },
    });

    this.slider.on('transitionEnd', () => {
      this.callback(this.options[this.slider.activeIndex][this.id]);
    });
  },
  beforeDestroy() {
    this.slider.destroy(true, true);
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  height: 150px;
  user-select: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 25px;
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }

  .option {
    padding: 5px;
    height: 30px;
    cursor: grab;
    color: var(--grey);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.swiper-slide-active {
      color: var(--black);
    }
  }
}
</style>
