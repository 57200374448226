<template>
  <section>
    <div
      class="gallery"
    >
      <Grid
        col="6"
        row-gap="xs"
      >
        <div
          v-for="(img, indexImg) in data.gallery"
          :key="indexImg"
        >
          <Figure :data="{...img, ratio: true}" />
        </div>
        <Grid>
          <Spacer all="xs">
            <Label :data="{ value: data.caption }" />
          </Spacer>
        </grid>
      </grid>
    </div>
  </section>
</template>

<script>
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';

export default {
  name: 'Gallery',
  components: {
    Figure,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
