<template>
  <main>
    <Content
      :data="{
        title: notFound.title ? notFound.title : null,
        rich_text: notFound.text ? notFound.text : null,
        cta: notFound.cta ? notFound.cta : null,
      }"
      class="spacer--padding--t--s spacer--padding--t-s--xxl"
    />
    <Asteroid :data="{ img: notFound.img }" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Content from '@/components/blocks/content';
import Asteroid from '@/components/blocks/custom/asteroid';

export default {
  name: 'NotFound',
  components: {
    Content,
    Asteroid,
  },
  computed: {
    ...mapGetters(['options']),
    notFound() {
      return this.options.not_found;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  position: relative;
  min-height: 700px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding-bottom: var(--spacer-xs);

  @include mq(s) {
    min-height: calc(var(--vh) * 100 - 20px);
  }

  .label {
    text-transform: uppercase;
  }

  & > *:not(.asteroid-about) {
    position: relative;
    z-index: 2;
    pointer-events: none;

    ::v-deep a {
      pointer-events: auto;
    }
  }
}
</style>
