<template>
  <component
    :is="tag"
    :class="[
      'col',
      ...colSizes,
      ...colOffsets,
    ]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Col',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    size: {
      type: [Object, String, Boolean],
      default: false,
    },
    offset: {
      type: [Object, String, Boolean],
      default: false,
    },
  },
  computed: {
    colSizes() {
      const { size } = this;
      const sizes = [];
      if (typeof size === 'string' && size !== '') {
        sizes.push(`col-${size}`);
      } else if (typeof size === 'object') {
        Object.keys(size).forEach((key) => {
          if (key !== 'default') {
            sizes.push(`col-${key}-${size[key]}`);
          } else {
            sizes.push(`col-${size[key]}`);
          }
        });
      }
      return sizes;
    },
    colOffsets() {
      const { offset } = this;
      const offsets = [];
      if (typeof offset === 'string' && offset !== '') {
        offsets.push(`col-offset-${offset}`);
      } else if (typeof offset === 'object') {
        Object.keys(offset).forEach((key) => {
          if (key !== 'default') {
            offsets.push(`col-offset-${key}-${offset[key]}`);
          } else {
            offsets.push(`col-offset-${offset[key]}`);
          }
        });
      }
      return offsets;
    },
  },
};
</script>

<style>

</style>
