var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"single-episode"},[(!_vm.overlay)?_c('Spacer',{staticClass:"u-hidden--until-m",attrs:{"top":_vm.liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'}}):_vm._e(),_c('Spacer',{class:!_vm.overlay ? 'grid single-grid' : false,attrs:{"bottom":!_vm.overlay ? 'xl' : undefined}},[_c('div',{staticClass:"single-thumb"},[_c('ThumbPlayer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAudio),expression:"isAudio"}],attrs:{"data":_vm.post,"ratio":!_vm.overlay ? 1 : undefined,"title":_vm.title,"avoid-link":""}}),(_vm.post.acf.livestream_share && !_vm.isAudio)?_c('Live',{attrs:{"data":{
          src: _vm.post.acf.livestream_share,
          params: {
            enableInfoAndActivity: 'false',
            defaultDrawer: 'feed',
            autoPlay: 'false',
          },
          avoidScroll: true,
        }}}):_vm._e()],1),_c('Spacer',{class:[
        'single-wrapper',
        !_vm.overlay ? 'grid' : 'single-wrapper-overlay',
      ],attrs:{"template":_vm.overlay ? 'wrapper' : undefined}},[(!_vm.overlay)?_c('div',{staticClass:"pre-row"},[_c('div',{staticClass:"separator--row"})]):_vm._e(),_c('div',{staticClass:"single-heading"},[_c('Flex',{staticClass:"heading-meta",attrs:{"justify":"space-between","align":"center"}},[(_vm.post.acf.data)?_c('Label',{attrs:{"data":{ value: _vm.post.acf.data, size: 'xxs', tag: 'time' }}}):_vm._e(),_c('Flex',{attrs:{"column-gap":"xs"}},[(_vm.post.acf.livestream_share)?_c('Cta',{staticClass:"switch",attrs:{"data":{
                title: _vm.isAudio ? 'Video' : 'Audio',
                fn: _vm.toggleMedia,
              },"theme":"button"}}):_vm._e(),_c('Cta',{staticClass:"share",attrs:{"data":{
                title: 'Condividi',
                fn: _vm.openShare,
                size: 'xxs',
              },"theme":"button"}})],1)],1),_c('Title',{staticClass:"single-title",attrs:{"data":{ value: _vm.title, size: !_vm.overlay ? 'l' : 'm' }}}),_c('Artist',{attrs:{"data":_vm.post}}),(_vm.post.gds_taxonomies.tags)?_c('Flex',{staticClass:"meta",attrs:{"justify":"space-between","align":"baseline"}},[(_vm.post.gds_taxonomies.tags)?_c('div',{staticClass:"tags"},[_c('Tags',{attrs:{"data":_vm.post.gds_taxonomies.tags,"theme":_vm.overlay ? 'dark' : 'light',"lite":_vm.overlay}})],1):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"single-content"},[(_vm.content)?_c('RichText',{attrs:{"data":{ value: _vm.content.rendered, size: !_vm.overlay ? 's' : 'xs' }}}):_vm._e(),(_vm.post.acf.show)?_c('div',{staticClass:"show-link"},[_c('Cta',{attrs:{"data":{
              title: 'Tutti gli episodi',
              url: _vm.post.acf.show.link,
            },"theme":"rounded-full"}})],1):_vm._e()],1),(_vm.post.acf.tracklist && _vm.post.acf.tracklist.length > 0)?_c('div',{staticClass:"tracklist",style:({ '--rows': Math.floor(_vm.post.acf.tracklist.length / 2) + 1 })},[(_vm.overlay || _vm.$mq.isMobile)?_c('div',{class:[
            'separator--row',
            _vm.overlay ? 'separator--row--light separator--row--extra' : false,
          ]}):_vm._e(),_c('Spacer',{attrs:{"bottom":"xs"}},[_c('Label',{attrs:{"data":{ value: 'Tracklist', size: 'xxs' }}})],1),_c('div',{staticClass:"grid-tracklist"},_vm._l((_vm.post.acf.tracklist),function(track,index){return _c('div',{key:index,staticClass:"track"},[_c('Title',{staticClass:"track-artist",attrs:{"data":{ value: track.titolo, size: 's' }}}),_c('Label',{staticClass:"track-title",attrs:{"data":{ value: track.sottotitolo, size: 'xs' }}})],1)}),0)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }