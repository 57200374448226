<template>
  <Spacer
    x="xs"
    class="list"
  >
    <Label
      v-if="data.title"
      :data="{ value: data.title, size: 'xxs' }"
    />
    <Grid
      :col="{ default: 12, s: 6 }"
      row-gap="l"
    >
      <div
        v-for="(item, index) in data.items"
        :key="index"
        class="item"
      >
        <Content
          :data="content(item)"
          no-padding
        />
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import Label from '@/components/typo/label';

import Content from '@/components/blocks/content';

export default {
  name: 'Repeater',
  components: {
    Content,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    content(item) {
      const { title, rich_text } = item;

      return {
        label: title,
        label_size: 'xxs',
        rich_text,
        rich_text_size: 'm',
      };
    },
  },
};
</script>

<style lang="scss">
.list {
  p a {
    @include mq($and: $hover) {
      text-decoration-line: none;
    }
  }
}
</style>
