var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.popup ? 'transition' : 'div',{tag:"component",attrs:{"name":"newsletter","appear":""}},[(!_vm.popup || _vm.showPopup)?_c('div',{class:[
      _vm.popup ? 'popup-newsletter' : 'newsletter',
      _vm.section ? 'section-newsletter' : false,
    ]},[_c('div',{class:['form', _vm.avoid ? 'form-avoid' : false]},[(_vm.popup)?_c('Cta',{staticClass:"popup-newsletter-close",attrs:{"data":{
          title: 'Chiudi',
          fn: _vm.closePopup,
        },"theme":"button"}}):_vm._e(),(!_vm.section)?_c('Label',{attrs:{"data":{
          value: _vm.popup
            ? _vm.options.newsletter.text
            : 'Iscriviti alla newsletter',
          size: _vm.popup ? 'xxs' : undefined,
        }}}):_vm._e(),_c('Grid',{attrs:{"id":"mc-embedded-subscribe-form","tag":"form","row-gap":_vm.popup || _vm.section ? 's' : '0',"col":"12","action":"https://radioraheem.us12.list-manage.com/subscribe/post","target":"_blank","method":"post","name":"mc-embedded-subscribe-form","justify":_vm.section ? 'center' : undefined}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"id":"MERGE0","type":"email","name":"MERGE0","required":"","placeholder":"La tua mail qui"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('input',{attrs:{"id":"u","type":"hidden","name":"u","value":"3c1f609067f534481424cc8c3"}}),_c('input',{attrs:{"id":"id","type":"hidden","name":"id","value":"f43e8ccaf2"}}),_c('Cta',{style:(_vm.section && _vm.theme === 'light'
              ? {
                '--border': 'var(--black)',
                '--bg': 'var(--grey-l)',
              }
              : _vm.section
                ? { '--border': 'var(--white)' }
                : null),attrs:{"data":{
            title: 'Prosegui',
            fn: () => {},
          },"theme":_vm.popup || _vm.section ? 'rounded-full' : 'button'}})],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }