<template>
  <Grid
    class="socials"
    col="12"
    row-gap="xxs"
  >
    <Label :data="{ value: label, size: 'xxs' }" />
    <Flex
      gap
      row-gap="xs"
      column-gap="s"
    >
      <a
        v-for="(item, index) in data"
        :key="index"
        :href="item.link"
        v-html="item.label"
      />
    </Flex>
  </Grid>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Socials',
  components: {
    Label,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    label: {
      type: String,
      default: 'Segui su',
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
