<template>
  <main>
    <Spacer
      :top="liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'"
    />
    <Spacer
      class="results"
      x="xs"
      bottom="xxl"
    >
      <Grid
        class="pre-row"
        :col="{default: 12, s: 6, m: 3 }"
      >
        <div class="separator--row" />
        <div class="separator--row u-hidden--until-s " />
        <div class="separator--row u-hidden--until-s u-hidden--until-m" />
        <div class="separator--row u-hidden--until-s u-hidden--until-m" />
      </Grid>
      <Flex
        :key="JSON.stringify($route.query)"
        justify="space-between"
        class="results-title"
      >
        <Title
          :data="{ value: title, size: 'l' }"
          class="t-uppercase"
        />
        <Cta
          :data="{
            title: 'Nuova ricerca',
            fn: openSearch
          }"
          theme="button"
        />
      </Flex>
      <div v-if="results && results.length > 0">
        <Grid
          :col="{ default: 12, s: 6, m: 3 }"
        >
          <Thumb
            v-for="thumb in results"
            :key="thumb.id"
            :data="thumb"
            theme="default"
          />
        </Grid>
      </div>

      <Spacer
        v-if="loadMore"
        class="load-more t-center"
        top="xxl"
        use-margin
      >
        <Cta
          :data="{
            title: 'Carica altro',
            fn: setOffset
          }"
          theme="rounded"
        />
      </Spacer>
    </Spacer>
  </main>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Thumb from '@/components/thumb';

export default {
  name: 'Search',
  components: {
    Thumb,
    Title,
    Cta,
  },
  data() {
    return {
      cancelToken: null,
      found: false,
      results: null,
      prevRequest: null,
      showFilters: false,
      totalResults: undefined,
      params: {},
      page: 1,
      showResults: 0,
      loadMore: undefined,
    };
  },
  computed: {
    ...mapGetters(['liveStatus', 'getTag']),
    title() {
      let searchQuery = null;
      let searchString = '';
      if (!(this.results && this.results.length === 0 && !this.found)) {
        searchQuery = Object.keys(this.$route.query).filter((key) => key !== 'page');
        searchQuery.forEach((key) => {
          if (this.$route.query[key]) {
            if (key === 'category') {
              searchString += `${this.$route.query[key]}, `;
            }
            if ((key === 'genre' && !this.$route.query.subgenre && this.$route.query[key] !== 'all') || key === 'subgenre') {
              const tag = this.getTag(parseInt(this.$route.query[key], 10));
              searchString += `${tag.name}, `;
            }
            if (key === 'year' && this.$route.query[key] !== 'all') {
              searchString += `${this.$route.query[key]}, `;
            }
          }
        });

        if (searchString !== '') {
          searchString = searchString.slice(0, -2);
        }
      }
      return this.results && this.results.length === 0 && !this.found ? 'Nessun risultato' : this.results && this.results.length > 0 ? `Hai cercato: ${searchString} (<span>${this.showResults}</span>/${this.totalResults})` : 'Sto cercando';
    },
  },
  watch: {
    $route(to, old) {
      if (to.name === 'Search' && old.name === 'Search') {
        if (JSON.stringify(to.query) !== JSON.stringify(old.query)) {
          this.found = false;
          this.results = null;
          this.loadMore = undefined;
          this.totalResults = undefined;
          this.showResults = 0;
          this.request = to.query;
          this.page = 1;
          this.setRequest();
        }
      }
    },
  },
  mounted() {
    this.request = this.$route.query;
    this.setRequest();
  },
  methods: {
    async setRequest() {
      this.totalResults = undefined;

      if (this.request.s && this.request.s !== '') {
        this.params = {
          search: this.request.s,
          type: 'post',
          subtype: 'any',
          per_page: 60,
          page: this.page,
        };
      } else {
        this.params = {
          year: this.request.year !== 'all' ? this.request.year : undefined,
          tags: this.request.subgenre ? this.request.subgenre : this.request.genre && this.request.genre !== 'all' ? this.request.genre : undefined,
          show_category: this.request.category,
          type: 'post',
          subtype: 'episode',
          per_page: 60,
          page: this.page,
          discover_search: this.request.category ? true : undefined,
          is_random: this.request.genre === 'all' && this.request.year === 'all' && !this.request.category ? true : undefined,
        };
      }

      const parseParams = Buffer.from(JSON.stringify(this.params), 'binary').toString('base64');

      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }
      this.cancelToken = axios.CancelToken.source();

      if (parseParams !== this.prevRequest) {
        await this.$store.dispatch('getSearch', { params: this.params, cancelToken: this.cancelToken.token })
          .then((response) => {
            if (this.page === 1) this.results = [];

            this.showResults += response.data.length;
            this.totalResults = !this.params.is_random ? response.headers['x-wp-total'] : false;
            this.loadMore = this.showResults * this.page < this.totalResults;

            if (response.data && response.data.length > 0) {
              this.found = true;
              if (this.page === 1) {
                this.results = response.data;
              } else {
                this.results.push(...response.data);
              }
            } else {
              this.found = false;
              this.results = [];
            }
          });
      }

      this.prevRequest = parseParams;
    },
    setOffset() {
      this.page += 1;
      this.setRequest();
    },
    openSearch() {
      this.$store.commit('SET_OVERLAY', 'search');
    },
  },
};
</script>

<style lang="scss" scoped>
.results {
  &-title {
    ::v-deep span {
      color: var(--grey-d);
    }
    margin-bottom: var(--spacer-m);
  }

  .pre-row {
    margin-bottom: var(--spacer-xxs);
  }
}
</style>
