<template>
  <div :class="['thumb', `thumb--${layout}`]">
    <ThumbPlayer
      :data="data"
      :canvas="layout === 'thumb_large' ? true : false"
      :title="title"
    />

    <div class="thumb-content">
      <Flex
        class="meta"
        gap
        column-gap="l"
      >
        <Label
          v-if="data.acf.data && type === 'episode'"
          :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
        />

        <!-- <Label
          v-if="type !== 'episode'"
          class="subtype"
          :data="{ value: type, size: 'xxs' }"
        /> -->
      </Flex>

      <router-link
        :to="data.link ? $relativeUrl(data.link) : $relativeUrl(data.url)"
        class="title-link"
      >
        <Title
          :data="{ value: title }"
          :class="[!$mq.isMobile ? 't-ellipsis' : false]"
        />
      </router-link>

      <Artist :data="data" />

      <div
        v-if="data.gds_taxonomies.tags && type === 'episode'"
        class="tags"
      >
        <Tags
          :data="data.gds_taxonomies.tags"
          :lite="$mq.isMobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThumbPlayer from '@/components/thumb/components/thumb-player';
import Artist from '@/components/thumb/components/artist';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Tags from '@/components/typo/tags';

export default {
  name: 'ThumbLarge',
  components: {
    ThumbPlayer,
    Artist,
    Title,
    Label,
    Tags,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'thumb_large',
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
    type() {
      return this.data.subtype ? this.data.subtype : this.data.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;

  .title-link {
    display: block;
    margin-top: var(--spacer-xxs);

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .artist {
    // margin-top: var(--spacer-xxs);
  }
  .tags {
    margin-top: var(--spacer-s);
  }

  &--thumb_large {
    height: 100%;

    .thumb-content {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      z-index: 2;

      @include mq(m) {
        top: 0;
        bottom: auto;
      }
    }
  }

  &--thumb_extralarge {
    .thumb-player {
      @include mq(m) {
        width: calc(100% + 20px);
        margin-left: -10px;
      }

      ::v-deep .icon {
        max-width: none;
      }
    }

    .meta {
      margin-top: var(--spacer-xs);
    }
  }

  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-d);
  }
}
</style>
