import Vue from 'vue';

export default {
  ADD_ITEM(state, {
    type, item, lang, lite = false,
  }) {
    if (item && type && state[type][lang]) {
      Vue.set(state[type][lang], item.slug, { ...item, lite });
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      if (!state.pages[lang]) {
        state.pages[lang] = {};
      }

      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base;
        if (!state[restBase][lang]) {
          state[restBase][lang] = {};
        }
      });
    });
  },
};
