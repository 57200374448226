<template>
  <Spacer
    v-show="items.length > 0"
    :x="!data.slider ? 'xs' : undefined"
    :class="`archive--${data.layout}`"
    :bottom="
      data.layout === 'thumb_medium' && data.post_type === 'partner'
        ? '0'
        : data.groups
          ? 'xl'
          : 'xxl'
    "
  >
    <Grid
      :col="
        data.layout !== 'thumb_podcast_episode' ||
          (data.layout === 'thumb_podcast_episode' && groups.length > 1)
          ? '12'
          : undefined
      "
      row-gap="0"
      column-gap="0"
    >
      <Spacer :x="data.slider ? 'xs' : undefined">
        <Grid
          v-if="
            (!data.groupBy || groups.length < 2) &&
              data.layout !== 'thumb_podcast_episode' &&
              data.layout !== 'thumb_podcast_archive' &&
              data.layout !== 'thumb_half' &&
              data.layout !== 'thumb_archive' &&
              !(data.layout === 'thumb_archive_half' && data.post_type === 'galaxy_express')
          "
          class="pre-row"
          :col="data.layout === 'thumb_large' ? '12' : gridCol"
        >
          <div class="separator--row" />
          <div
            v-if="
              data.layout !== 'thumb_large' &&
                data.layout !== 'thumb_extralarge' &&
                data.layout !== 'thumb_full'
            "
            :class="[
              'separator--row',
              data.layout !== 'thumb_podcast' ? 'u-hidden--until-s' : false,
            ]"
          />
          <div
            v-if="
              data.layout !== 'thumb_large' &&
                data.layout !== 'thumb_extralarge' &&
                data.layout !== 'thumb_full' &&
                data.layout !== 'thumb_archive_half'
            "
            class="separator--row u-hidden--until-s u-hidden--until-m"
          />
          <div
            v-if="
              data.layout !== 'thumb_large' &&
                data.layout !== 'thumb_extralarge' &&
                data.layout !== 'thumb_full' &&
                data.layout !== 'thumb_archive_half'
            "
            class="separator--row u-hidden--until-s u-hidden--until-m"
          />
        </Grid>
      </Spacer>
      <Spacer :x="data.slider ? 'xs' : undefined">
        <Column
          v-if="title"
          :size="data.layout === 'thumb_podcast_episode' ? { default: 12, s: 4 } : undefined"
        >
          <div class="wrapper-cta title-archive">
            <Cta
              v-if="data.cta"
              :data="data.cta"
              class="link-cta"
            />
            <Flex
              justify="space-between"
              nowrap
              gap
            >
              <Title
                :data="{ value: title, size: 'l' }"
                class="t-uppercase"
              />

              <Cta
                v-if="data.cta"
                :data="data.cta"
                theme="button"
              />
            </Flex>
            <Title
              v-if="data.subtitle"
              :data="{ value: data.subtitle, size: 'm' }"
              class="subtitle-archive wrapper--l"
            />
          </div>
        </Column>
      </Spacer>
      <template v-if="!data.groupBy || groups.length < 2">
        <Column
          ref="grid"
          :size="data.layout === 'thumb_podcast_episode' ? { default: 12, s: 8 } : undefined"
          :class="[data.slider ? 'swiper-container' : false]"
        >
          <Grid
            v-if="items.length > 0"
            :class="['grid-items', data.slider ? 'swiper-wrapper' : false]"
            :col="
              data.layout !== 'thumb_large' && data.layout !== 'thumb_archive' ? gridCol : undefined
            "
            :row-gap="data.layout === 'thumb_podcast_episode' ? 'l' : undefined"
            :style="data.layout === 'thumb_large' ? { '--last-line': items.length + 1 } : false"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="[
                'item',
                item.preview_thumb ? 'item--large' : false,
                data.slider ? 'swiper-slide' : false,
              ]"
            >
              <div
                v-if="
                  data.layout === 'thumb_large' || (data.layout === 'thumb_small' && $mq.isMobile)
                "
                class="separator--row"
              />
              <component
                :is="thumb(item.type, index)"
                :data="item"
                :layout="data.layout"
                :index="index"
              />
            </div>
          </Grid>
          <Grid
            v-if="
              items.length > 0 && data.layout === 'thumb_medium' && data.post_type === 'partner'
            "
            :class="['grid-items', data.slider ? 'swiper-wrapper' : false, 'grid-end']"
            :col="
              data.layout !== 'thumb_large' && data.layout !== 'thumb_archive' ? gridCol : undefined
            "
            :row-gap="data.layout === 'thumb_podcast_episode' ? 'l' : undefined"
            :style="data.layout === 'thumb_large' ? { '--last-line': items.length + 1 } : false"
          >
            <div
              v-for="i in $mq.isMobile ? 2 : 4"
              :key="i"
              class="separator--row"
            />
          </Grid>
          <div
            v-show="data.slider && !$mq.isMobile"
            :class="{
              'swiper-button-prev': true,
              'swiper-button--thumb-small': data.layout === 'thumb_small',
            }"
          >
            <PrevButton v-if="!$mq.isMobile" />
          </div>
          <div
            v-show="data.slider && !$mq.isMobile"
            :class="{
              'swiper-button-next': true,
              'swiper-button--thumb-small': data.layout === 'thumb_small',
            }"
          >
            <NextButton v-if="!$mq.isMobile" />
          </div>
        </Column>
      </template>

      <template v-else-if="groups && groups.length >= 2">
        <Grid col="12">
          <Grid
            v-for="group in groups"
            :key="group.id"
            row-gap="0"
            :col="data.layout !== 'thumb_podcast_episode' ? '12' : undefined"
          >
            <Grid
              v-if="data.layout !== 'thumb_podcast_episode'"
              class="pre-row"
              :col="gridCol"
            >
              <div class="separator--row" />
              <div
                v-if="data.layout !== 'thumb_podcast_episode'"
                class="separator--row u-hidden--until-s"
              />
              <div
                v-if="data.layout !== 'thumb_podcast_episode'"
                class="separator--row u-hidden--until-s u-hidden--until-m"
              />
              <div
                v-if="data.layout !== 'thumb_podcast_episode'"
                class="separator--row u-hidden--until-s u-hidden--until-m"
              />
            </Grid>
            <Column
              :size="data.layout === 'thumb_podcast_episode' ? { default: 12, s: 4 } : undefined"
            >
              <Flex
                justify="space-between"
                class="t-uppercase title-archive"
              >
                <Title :data="{ value: group.name, size: 'l' }" />
                <Cta
                  v-if="data.layout !== 'thumb_podcast_episode'"
                  :data="{
                    title: 'Vai allo show',
                    url: group.link,
                  }"
                  theme="button"
                />
              </Flex>
            </Column>
            <Column
              :size="data.layout === 'thumb_podcast_episode' ? { default: 12, s: 8 } : undefined"
            >
              <Grid
                v-if="group.items.length > 0"
                :col="gridCol"
                :row-gap="data.layout === 'thumb_podcast_episode' ? 'l' : undefined"
              >
                <div
                  v-for="(item, index) in group.items"
                  :key="index"
                  class="item"
                >
                  <component
                    :is="thumb(item.type)"
                    :data="item"
                    :layout="data.layout"
                  />
                </div>
              </Grid>
            </Column>
          </Grid>
        </Grid>
      </template>

      <div>
        <!-- <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    /> -->

        <Spacer
          v-if="loadMore && items.length > 0"
          class="load-more t-center"
          top="xxl"
          use-margin
        >
          <Cta
            :data="{
              title: 'Carica altro',
              fn: requestOffset,
            }"
            theme="rounded"
            :style="{ '--border': 'var(--white)' }"
          />
        </Spacer>
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import Swiper, { Mousewheel, Navigation } from 'swiper';

import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import ThumbSmall from '@/components/thumb/small';
import ThumbLarge from '@/components/thumb/large';
import ThumbNews from '@/components/thumb/news';
import ThumbSinglePodcast from '@/components/thumb/single-podcast';
import ThumbPodcast from '@/components/thumb/podcast';
import ThumbSinglePodcastArchive from '@/components/thumb/single-podcast-archive';

import PrevButton from '@/components/icons/button-prev';
import NextButton from '@/components/icons/button-next';

import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('small', ThumbSmall);
thumbs.set('large', ThumbLarge);
thumbs.set('single-podcast', ThumbSinglePodcast);
thumbs.set('podcast', ThumbPodcast);
thumbs.set('single-podcast-archive', ThumbSinglePodcastArchive);
thumbs.set('galaxy_express', ThumbNews);
thumbs.set('post', ThumbNews);

Swiper.use([Mousewheel, Navigation]);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    PrevButton,
    NextButton,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  computed: {
    gridCol() {
      if (this.data.layout === 'thumb_podcast_episode') {
        return { default: 12 };
      }

      if (this.data.layout === 'thumb_podcast_archive') {
        return { default: 12 };
      }

      if (this.data.layout === 'thumb_podcast') {
        return { default: 6, m: 3 };
      }

      if (this.data.layout === 'thumb_extralarge') {
        return { default: 12 };
      }

      if (this.data.layout === 'thumb_full') {
        return { default: 12 };
      }

      if (this.data.layout === 'thumb_half' || this.data.layout === 'thumb_archive_half') {
        return { default: 12, s: 6 };
      }

      return { default: 12, s: 6, m: 3 };
    },
    title() {
      if (
        this.data.title
        && this.data.layout === 'thumb_podcast_episode'
        && this.groups.length < 2
      ) {
        return this.data.title;
      }
      if (!this.data.title && this.groups.length === 1) {
        return this.groups[0].name;
      }
      if (this.data.layout === 'thumb_podcast_episode') {
        return false;
      }
      return this.data.title;
    },
  },
  watch: {
    items(val) {
      if (
        val.length
        && (this.data.layout === 'thumb_medium' || this.data.layout === 'thumb_small')
        && !this.data.infinite_scroll
        && this.data.slider
      ) {
        this.initSlider();
      }
    },
  },
  // beforeUnmount() {
  //   if (this.slider) {
  //     this.$bus.$off('windowResized', this.watchGrid);
  //   }
  // },
  methods: {
    thumb(key, index) {
      if (this.data.layout === 'thumb_podcast_episode') return thumbs.get('single-podcast');
      if (this.data.layout === 'thumb_podcast_archive') return thumbs.get('single-podcast-archive');
      if (this.data.layout === 'thumb_small') return thumbs.get('small');
      if (this.data.layout === 'thumb_extralarge') {
        return thumbs.get('large');
      }
      if (this.data.layout === 'thumb_large') {
        if (index === 0) return thumbs.get('large');
        return thumbs.get('small');
      }

      if (this.data.layout === 'thumb_archive' || this.data.layout === 'thumb_archive_half') {
        return thumbs.get('post');
      }

      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
    initSlider() {
      this.$nextTick(() => {
        this.slider = new Swiper(this.$refs.grid.$el, {
          // cssMode: true,
          // roundLengths: true,
          // simulateTouch: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          slidesPerView: 1.2,
          spaceBetween: 10,
          breakpoints: {
            740: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          },
        });

        // if (this.$mq.isMobile) this.slider.disable();
        // this.$bus.$on('windowResized', this.watchGrid);
      });
    },
    // watchGrid() {
    //   if (this.slider) {
    //     if (this.$mq.isMobile && this.slider.enabled) {
    //       this.slider.disable();
    //     } else if (!this.$mq.isMobile && !this.slider.enabled) {
    //       this.slider.enable();
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
.infinite {
  visibility: hidden;
}
.title-archive {
  margin-bottom: var(--spacer-xxl);
  @include mq(s) {
    margin-bottom: var(--spacer-m);
  }
}
.pre-row {
  margin-bottom: var(--spacer-xxs);
}

.separator--row {
  position: relative;
  z-index: 2;
}

.archive--thumb_large {
  .grid-items {
    row-gap: var(--row-gap-s);

    @include mq(m) {
      row-gap: var(--row-gap-s);
    }
    align-items: start;
    align-content: start;

    & > {
      .item {
        grid-column-start: auto;
        grid-column-end: span 12;

        @include mq(m) {
          grid-column-start: 9;
          grid-column-end: span 4;

          &:first-child {
            grid-column-start: 1;
            grid-column-end: span 8;
            grid-row: 1 / var(--last-line, 10);
            height: 100%;
          }
        }
      }
    }
  }
}

.archive--thumb_archive {
  .grid-items {
    & > {
      .item {
        grid-column-start: auto;
        grid-column-end: span 12;

        @include mq(m) {
          grid-column-end: span 6;
        }

        &:first-child,
        &--large {
          grid-column-start: auto;
          grid-column-end: span 12;
        }
      }
    }
  }
}

.grid-items {
  & > .item {
    .separator--row {
      margin-bottom: var(--spacer-xs);
    }
  }
}
.swiper-container {
  width: 100%;

  &.swiper-container-initialized {
    padding: 0 var(--spacer-xs);

    .swiper-wrapper {
      display: flex;
      row-gap: 0;
      column-gap: 0;
    }
  }
}

.wrapper-cta {
  position: relative;
  .cta {
    // flex-shrink: 0;
    text-align: right;
  }

  .link-cta {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;

    * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & + * {
      @include mq($and: $hover) {
        transition: color 0.2s $ease-custom;
      }
    }

    &:hover + * {
      @include mq($and: $hover) {
        color: var(--grey-d);
      }
    }
  }
}

.subtitle-archive {
  margin-top: var(--spacer-xxs);
}

.thumb {
  a {
    @include mq($and: $hover) {
      transition: color 0.2s $ease-custom;
      &:hover {
        color: var(--grey-d);
      }
    }
  }
}

.swiper-button {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  width: 16%;

  &:not(.swiper-button--thumb-small) {
    width: 25%;
    height: initial;
    @include mq(s) {
      width: calc(((100% - 10px) / 2));
    }
    @include mq(m) {
      width: calc(((100% - 30px) / 4));
    }
    &::before {
      content: "";
      display: block;
      height: 0;
      padding-top: calc(56.4875% * 4);
      @include mq(s) {
        padding-top: 56.4875%;
      }
    }
  }
  & > * {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    position: absolute;
    cursor: pointer;
  }

  &-next {
    @extend .swiper-button;
    right: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 50%);

    & > * {
      pointer-events: initial;
      right: var(--spacer-s);
    }
  }
  &-prev {
    @extend .swiper-button;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 50%);

    & > * {
      pointer-events: initial;
      left: var(--spacer-s);
    }
  }

  transition: opacity 0.3s;
}

.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.grid-end {
  visibility: hidden;
  @include mq(s) {
    visibility: initial;
    padding-top: var(--spacer-xxl);
  }
}
</style>
