<template>
  <section>
    <VideoPlayer :data="data" />
  </section>
</template>

<script>
import 'plyr/dist/plyr.css';
import VideoPlayer from '@/components/media/video';

export default {
  name: 'Video',
  components: {
    VideoPlayer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
