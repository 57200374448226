<template>
  <router-link
    class="thumb"
    :to="$relativeUrl(data.link)"
  >
    <Figure
      :data="{ ...data.gds_featured_image, ratio: 1 }"
      disable-caption
    />

    <div class="thumb-content">
      <Label
        v-if="data.acf.data && type === 'episode'"
        class="meta"
        :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
      />

      <!-- <Label
        v-if="type !== 'episode'"
        class="subtype"
        :data="{ value: type, size: 'xxs' }"
      /> -->

      <div class="title-link">
        <Title
          :data="{ value: title }"
          class="t-ellipsis"
        />
      </div>

      <Artist :data="data" />
    </div>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Artist from '@/components/thumb/components/artist';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';

export default {
  name: 'ThumbPodcast',
  components: {
    Figure,
    Artist,
    Title,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      default: 'thumb_medium',
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
    type() {
      return this.data.subtype ? this.data.subtype : this.data.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;

  @include mq($and: $hover) {
    transition: color 0.2s $ease-custom;
    &:hover {
      color: var(--grey-d);
    }
  }

  .title-link {
    display: block;
    margin-top: var(--spacer-xxs);

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .meta {
    display: block;
    margin-top: var(--spacer-xs);
  }
  .artist,
  .show-link {
    // margin-top: var(--spacer-xxs);
  }
  .show-link {
    display: block;
  }
  .tags {
    margin-top: var(--spacer-s);
  }

  &-content {
    position: relative;
  }

  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-d);
  }
}
</style>
