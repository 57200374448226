var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Flex',{staticClass:"artist",attrs:{"align":"baseline"}},[(
      _vm.data.acf.show &&
        _vm.data.acf.show.acf &&
        _vm.data.acf.show.acf.resident_guest_programma &&
        _vm.data.acf.show.acf.resident_guest_programma[0]
    )?_c('router-link',{attrs:{"to":_vm.$relativeUrl(_vm.data.acf.show.acf.resident_guest_programma[0].link)}},[_c('Title',{staticClass:"t-ellipsis",attrs:{"data":{
        value: _vm.data.acf.show.acf.resident_guest_programma[0].title.rendered,
        size: _vm.size,
      }}})],1):_vm._e(),(_vm.data.acf.guest && _vm.data.acf.guest.length > 0)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(' w/ ')}}),_vm._l((_vm.data.acf.guest),function(guest,index){return _c('span',{key:guest.id,staticClass:"guest"},[(index > 0 && index !== _vm.data.acf.guest.length)?_c('span',{domProps:{"innerHTML":_vm._s(', ')}}):_vm._e(),_c('router-link',{attrs:{"to":_vm.$relativeUrl(guest.link)},domProps:{"innerHTML":_vm._s(guest.title.rendered)}})],1)})],2):(
      _vm.data.acf.show && _vm.data.acf.show.acf && _vm.data.acf.show.acf.subtitle
    )?_c('Title',{attrs:{"data":{ value: _vm.data.acf.show.acf.subtitle, size: _vm.size }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }