<template>
  <main>
    <Spacer
      :top="liveStatus !== 'video' ? { default: 's', s: '0' } : 's'"
    />
    <ArchiveComponent
      :data="{
        layout: 'thumb_archive',
        infinite_scroll: true,
        post_type: archive.name,
        posts_per_scroll: 24,
        thumbs: false,
      }"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import ArchiveComponent from '@/components/blocks/archive';

export default {
  name: 'Archive',
  components: {
    ArchiveComponent,
  },
  data() {
    return {
      archive: null,
    };
  },
  computed: {
    ...mapGetters(['postTypeFromArchive', 'liveStatus']),
  },
  created() {
    this.archive = this.postTypeFromArchive(this.$route.params.pathMatch);
  },
};
</script>
