<template>
  <main>
    <Figure
      v-if="post.acf.background"
      :data="{
        ...post.acf.background,
      }"
      disable-caption
      class="background"
    />

    <div class="main-wrapper">
      <div
        v-if="!logged"
        class="login"
      >
        <form method="POST">
          <input
            id="password"
            v-model="password"
            type="password"
            name="password"
            required
            placeholder="Password"
          >
          <Cta
            :data="{
              title: 'Entra',
              fn: () => {},
            }"
            :theme="'button'"
          />
        </form>
      </div>

      <div v-else>
        <div
          v-if="post.gds_featured_image && post.acf.type === 'client'"
          class="logo"
        >
          <Figure
            :data="{
              ratio: true,
              default: post.gds_featured_image,
              mobile: post.acf.featured_image_mobile,
            }"
            disable-caption
          />
        </div>
        <Title
          v-else
          :data="{ value: post.title.rendered, size: 'l' }"
        />
        <Grid>
          <Column
            v-if="post.acf.current_track"
            :size="{ default: 12 }"
          >
            <p
              :style="{ opacity: playing ? 1 : 0 }"
              class="metadata"
            >
              <Spacer
                use-margin
                bottom="xxs"
              >
                <Label :data="{ value: 'Now Playing', size: 'xxs' }" />
              </Spacer>
              <Spacer x="xs">
                <div
                  class="typo--xl"
                  v-html="metadata || '&nbsp;'"
                />
              </Spacer>
            </p>
          </Column>
        </Grid>
        <div>
          <Column :size="{ default: 12 }">
            <Cta
              :theme="'radio'"
              :data="{
                title: label,
                fn: () => {
                  toggle();
                },
              }"
            />
            <audio
              v-show="false"
              v-if="fallback"
              ref="fallbackEl"
              :src="post?.acf?.url"
            />
          </Column>
        </div>
        <Wrapper
          :size="'m'"
          class="copyright"
        >
          <div
            class="typo--xs"
            v-html="footer.copyright"
          />
        </Wrapper>
      </div>
    </div>
  </main>
</template>

<script>
import IcecastMetadataPlayer from 'icecast-metadata-player';
import { mapGetters } from 'vuex';

import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Figure,
    Label,
    Title,
    Cta,
  },
  mixins: [data],
  data() {
    return {
      player: null,
      logged: false,
      password: '',
      metadata: '',
      playing: false,
      label: 'Play',
      fallback: false,
      intervalFallback: undefined,
    };
  },
  computed: {
    ...mapGetters(['options']),

    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
  created() {
    this.logged = !!this.post?.acf?.url;
  },
  mounted() {
    this.player = new IcecastMetadataPlayer(this.post?.acf?.url, {
      onMetadata: (metadata) => {
        this.metadata = metadata.StreamTitle;
      },
      onPlay: () => {
        if (this.intervalFallback) {
          clearInterval(this.intervalFallback);
        }

        this.playing = true;
        this.label = 'Stop';
      },
      onStop: () => {
        this.playing = false;
        this.label = 'Play';
      },
      onError: () => {
        this.fallback = true;
        this.player.stop();

        this.$nextTick(() => {
          // this.setMetadataFallback();
          // this.intervalFallback = setInterval(() => {
          //   this.setMetadataFallback();
          // }, 5000);

          this.$refs.fallbackEl.play().then(() => {
            this.playing = true;
            this.label = 'Stop';
          });
        });
      },
    });
  },

  beforeDestroy() {
    if (this.intervalFallback) {
      clearInterval(this.intervalFallback);
    }
  },
  methods: {
    async toggle() {
      this.label = 'Loading...';
      if (this.fallback) {
        if (this.$refs.fallbackEl.paused) {
          await this.$nextTick();
          this.$refs.fallbackEl.src = '';
          this.$refs.fallbackEl.src = this.post?.acf?.url;
          this.$refs.fallbackEl.play();
        } else {
          this.$refs.fallbackEl.pause();
        }

        this.playing = !this.$refs.fallbackEl.paused;
        this.label = this.playing ? 'Stop' : 'Play';
      } else if (this.playing) {
        this.player.stop();
      } else {
        this.player.play();
      }
    },
    async setMetadataFallback() {
      const metadataUrl = this.post?.acf?.url.replace(';1/', 'currentsong?sid=1');
      const metadataFetch = await this.$store.dispatch('sendRequest', {
        params: {
          url: metadataUrl,
        },
        fn: 'fetch_metadata',
      });

      this.metadata = metadataFetch?.data;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  height: 100vh;

  .main-wrapper {
    position: relative;
    height: 100%;
    z-index: 2;

    & > * {
      padding: 80px 80px 20px 80px;
      height: 100%;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      text-align: center;
      align-content: center;
    }

    .login {
      grid-template-rows: 1fr;
    }
  }

  .metadata {
    margin: 0 auto;
  }
}

.logo {
  width: 250px;
  margin: 0 auto;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    opacity: 0.2;

    [data-theme="light"] & {
      background: var(--white);
    }
  }

  ::v-deep {
    picture {
      width: 100%;

      img {
        position: relative;
      }
    }
  }
}

.copyright {
  align-self: end;
}
</style>
