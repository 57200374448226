/* eslint-disable no-undef */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const { state } = __VUE_WORDPRESS__;

export default {
  state,
  mutations,
  getters,
  actions,
};
