<template>
  <component
    :is="popup ? 'transition' : 'div'"
    name="newsletter"
    appear
  >
    <div
      v-if="!popup || showPopup"
      :class="[
        popup ? 'popup-newsletter' : 'newsletter',
        section ? 'section-newsletter' : false,
      ]"
    >
      <div :class="['form', avoid ? 'form-avoid' : false]">
        <Cta
          v-if="popup"
          :data="{
            title: 'Chiudi',
            fn: closePopup,
          }"
          theme="button"
          class="popup-newsletter-close"
        />
        <Label
          v-if="!section"
          :data="{
            value: popup
              ? options.newsletter.text
              : 'Iscriviti alla newsletter',
            size: popup ? 'xxs' : undefined,
          }"
        />
        <Grid
          id="mc-embedded-subscribe-form"
          tag="form"
          :row-gap="popup || section ? 's' : '0'"
          col="12"
          action="https://radioraheem.us12.list-manage.com/subscribe/post"
          target="_blank"
          method="post"
          name="mc-embedded-subscribe-form"
          :justify="section ? 'center' : undefined"
        >
          <input
            id="MERGE0"
            v-model="email"
            type="email"
            name="MERGE0"
            required
            placeholder="La tua mail qui"
          >

          <!-- <div
            v-if="popup"
            class="privacy typo--xxs"
          >
            <input
              :id="popup ? 'popup-privacy' : 'privacy'"
              type="checkbox"
              name="privacy"
              value="0"
              required
            > <label :for="popup ? 'popup-privacy' : 'privacy'"><span>Accetto i <a href="#!">termini e le condizioni</a>. Non facciamo spam.</span></label>
          </div> -->

          <input
            id="u"
            type="hidden"
            name="u"
            value="3c1f609067f534481424cc8c3"
          >
          <input
            id="id"
            type="hidden"
            name="id"
            value="f43e8ccaf2"
          >

          <Cta
            :data="{
              title: 'Prosegui',
              fn: () => {},
            }"
            :theme="popup || section ? 'rounded-full' : 'button'"
            :style="
              section && theme === 'light'
                ? {
                  '--border': 'var(--black)',
                  '--bg': 'var(--grey-l)',
                }
                : section
                  ? { '--border': 'var(--white)' }
                  : null
            "
          />
        </Grid>
      </div>
    </div>
  </component>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import Label from '@/components/typo/label';
import Cta from '@/components/typo/cta';

export default {
  name: 'Newsletter',
  components: {
    Label,
    Cta,
  },
  props: {
    popup: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
      showPopup: !Cookies.get('radioraheem-newsletter'),
    };
  },
  computed: {
    ...mapGetters(['options', 'theme']),
  },

  methods: {
    closePopup() {
      Cookies.set('radioraheem-newsletter', '1', { expires: 1 });
      this.showPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-avoid {
    cursor: progress;

    form {
      pointer-events: none;
    }
  }

  form {
    .cta {
      margin-top: 10px;
    }
  }
}

.section-newsletter {
  text-align: center;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .form {
    justify-content: center;
  }

  input[type="email"] {
    @extend %typo--l;
    text-align: center;
  }

  .cta {
    margin-top: var(--spacer-s);
    width: 400px;
    max-width: 100%;
  }
}

.popup-newsletter {
  position: relative;
  background: var(--white);
  color: var(--black);
  width: 100%;
  --color: var(--white);
  --bg: var(--black);

  @include mq(s) {
    width: 380px;
  }

  .form {
    position: relative;
    padding: var(--spacer-xs) var(--spacer-xs);

    .label {
      text-transform: none;
      margin-bottom: 30px;
      max-width: calc(100% - 60px);
    }

    .privacy {
      text-transform: none;
      margin-bottom: var(--spacer-xxs);

      input {
        position: absolute;
        width: 0;
        height: 0;

        &:checked + label {
          &::after {
            opacity: 1;
          }
        }
      }

      label {
        position: relative;
        display: block;
        cursor: pointer;
        line-height: 0;
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: 0;
          left: 0;
          width: 12px;
          height: 12px;
          border: 1px solid var(--black);
          margin-right: var(--spacer-xxs);
        }

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 12px;
          height: 12px;
          background: var(--black);
          transform: scale(0.6);
          opacity: 0;
          border: 1px solid transparent;
        }
      }
    }
  }

  &-close {
    position: absolute;
    right: var(--spacer-xs);
    top: var(--spacer-xxs);
  }

  input[type="email"] {
    @extend %typo--m;
    padding-bottom: var(--spacer-xxs);
    border-bottom: 1px solid var(--black);
  }
}

.newsletter-enter-active,
.newsletter-leave-active {
  transition: all 0.8s $ease-custom;
}
.newsletter-leave-to {
  opacity: 0;
}
.newsletter-enter,
.newsletter-leave-to {
  transform: translateY(100%);

  @include mq(m) {
    transform: translateY(100%) translateY(10px);
  }
}
</style>
