<template>
  <Spacer
    x="xs"
    class="content-list"
  >
    <Grid :col="columns">
      <div
        v-for="i in !$mq.isMobileS ? columns.default / columns.s : 1"
        :key="i"
        class="separator--row"
      />
    </Grid>
    <Label
      v-if="data.title"
      class="t-uppercase"
      :data="{ value: data.title, size: 'l' }"
    />
    <Spacer
      top="l"
      bottom="l"
    >
      <Grid
        v-if="!data.carousel"
        :col="columns"
        row-gap="l"
      >
        <div
          v-for="(item, index) in data.items"
          :key="index"
          :class="['item', { empty: isItemEmpty(item) }]"
        >
          <Content
            class="content"
            :data="content(item)"
            no-padding
            from-list
          />
        </div>
      </Grid>
      <div
        v-else-if="data.only_images"
        :class="[{ 'only-images': data.only_images }]"
      >
        <MarqueeText
          :key="$mq.isMobile"
          :repeat="10"
          :duration="60"
        >
          <div class="marquee-content">
            <Content
              v-for="(item, index) in data.items"
              :key="index"
              :class="['item']"
              class="content"
              :data="content(item)"
              no-padding
              from-list
              :only-images="data.only_images"
            />
          </div>
        </MarqueeText>
      </div>
      <div
        v-else
        ref="swiperEl"
        :class="['swiper-container no-padding']"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in data.items"
            :key="index"
            :class="['swiper-slide', 'item']"
          >
            <Content
              class="content"
              :data="content(item)"
              no-padding
              from-list
            />
          </div>
        </div>
        <div
          v-show="!$mq.isMobile"
          class="swiper-studio-prev"
        >
          <PrevButton v-if="!$mq.isMobile" />
        </div>
        <div
          v-show="!$mq.isMobile"
          class="swiper-studio-next"
        >
          <NextButton v-if="!$mq.isMobile" />
        </div>
      </div>
    </Spacer>
  </Spacer>
</template>

<script>
import Swiper, { Autoplay, Navigation } from 'swiper';
import Label from '@/components/typo/label';
import Content from '@/components/blocks/content';
import PrevButton from '@/components/icons/button-prev';
import NextButton from '@/components/icons/button-next';

Swiper.use([Autoplay, Navigation]);
export default {
  name: 'Repeater',
  components: {
    Content,
    Label,
    PrevButton,
    NextButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columns() {
      if (this.data.carousel) {
        return { default: 12, s: 12 };
      }
      if (this.data.items?.length > 2 && !this.data.items.some((item) => item.image)) {
        return { default: 12, s: 3 };
      }
      return { default: 12, s: 6 };
    },
  },
  mounted() {
    if (this.data.carousel) {
      this.initSlider();
    }
  },
  methods: {
    content(item) {
      return {
        title: item?.content?.title,
        title_size: 'xxs',
        label: item?.content?.label,
        label_size: 'xxs',
        rich_text: item?.content?.rich_text,
        rich_text_size: 'm',
        theme: 'banner',
        ...item,
      };
    },
    isItemEmpty(item) {
      return (
        !item?.content?.image
        && (!item?.content?.title || item?.content?.title === '')
        && (!item?.content?.label || item?.content?.label === '')
        && (!item?.content?.cta || item?.content?.cta === '')
      );
    },
    initSlider() {
      this.$nextTick(() => {
        this.slider = new Swiper(this.$refs.swiperEl, {
          navigation: {
            nextEl: '.swiper-studio-next',
            prevEl: '.swiper-studio-prev',
          },
          allowTouchMove: !this.data.only_images,
          slidesPerView: this.data.only_images ? 3 : 1.1,
          spaceBetween: 10,
          autoplay: this.data.only_images
            ? {
              delay: 1,
              disableOnInteraction: false,
            }
            : false,
          speed: this.data.only_images ? 6000 : 300,
          loop: this.data.only_images,
          breakpoints: !this.data.only_images
            ? {
              600: {
                slidesPerView: 2,
              },
              1880: {
                slidesPerView: 3,
              },
            }
            : {
              600: {
                slidesPerView: 4,
              },
              1880: {
                slidesPerView: 5,
              },
            },
        });
      });
    },
  },
};
</script>

<style lang="scss">
.content-list {
  width: 100%;
  overflow: hidden;
  p a {
    @include mq($and: $hover) {
      text-decoration-line: none;
    }
  }
}
.content {
  .thumb-content,
  .thumb-text {
    padding-right: var(--spacer-m);
  }
}

.content-list {
  .swiper-container {
    .thumb-content,
    .thumb-text {
      @extend %typo--s;
    }
  }

  .item.empty {
    display: none;
    @include mq(m) {
      display: block;
    }
  }

  .no-padding {
    margin-left: calc(-1 * var(--spacer-xs));
    margin-right: calc(-1 * var(--spacer-xs));
    width: calc(100% + (var(--spacer-xs) * 2));
  }

  .swiper-studio {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    width: 25%;
    height: initial;
    @include mq(s) {
      width: calc(((100% - 10px) / 2));
    }
    @include mq(m) {
      width: calc(((100% - 30px) / 4));
    }
    &::before {
      content: "";
      display: block;
      height: 0;
      @include mq(s) {
        padding-top: calc(56.4875% * 2.45);
      }
      @include mq(xxxl) {
        padding-top: calc(56.4875% * 1.55);
      }
    }
    & > * {
      top: 50%;
      transform: translate3d(0, -50%, 0);
      position: absolute;
      cursor: pointer;
    }

    &-next {
      @extend .swiper-studio;
      right: 0;
      & > * {
        pointer-events: initial;
        right: var(--spacer-s);
      }
    }
    &-prev {
      @extend .swiper-studio;
      left: 0;
      & > * {
        pointer-events: initial;
        left: var(--spacer-s);
      }
    }
  }
}
.only-images {
  margin-left: calc(-1 * var(--spacer-s));
  width: calc(100% + (var(--spacer-s) * 2));
}
.marquee-content {
  display: flex;
  flex-flow: nowrap;
  & > * {
    width: 200px;
    margin-right: var(--spacer-s);
  }
}
</style>
