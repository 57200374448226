<template>
  <section>
    <div
      ref="gallery"
      class="swiper-container"
    >
      <div class="swiper-wrapper">
        <div
          v-for="(img, indexImg) in data.carousel"
          :key="indexImg"
          class="swiper-slide"
        >
          <Figure :data="{...img, ratio: true}" />
        </div>
      </div>

      <div
        v-if="gallery"
        class="swiper-navigation"
      >
        <div
          :class="['swiper-navigation-prev', gallery.isBeginning ? 'swiper-navigation-disable' : false]"
          @click="goPrev"
        />
        <div
          :class="['swiper-navigation-next', gallery.isEnd ? 'swiper-navigation-disable' : false]"
          @click="goNext"
        />
      </div>
    </div>
    <Spacer all="xs">
      <Label :data="{ value: data.caption }" />
    </Spacer>
  </section>
</template>

<script>
import Swiper from 'swiper';
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';

export default {
  name: 'Carousel',
  components: {
    Figure,
    Label,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gallery: null,
    };
  },
  mounted() {
    this.gallery = new Swiper(this.$refs.gallery, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      allowTouchMove: this.$mq.isMobile,
    });
  },
  methods: {
    goPrev() {
      this.gallery.slidePrev();
    },
    goNext() {
      this.gallery.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  flex: 0 0 auto;
  width: 100%;
  max-width: 66vw;
}

.swiper-button {
  cursor: pointer;
}

.swiper-container {
  position: relative;
}

.swiper-navigation {
  display: none;
  @include mq($and: $hover)  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    &-disable {
      pointer-events: none;
    }

    &-prev {
      flex: 0 0 66.66vw;
      cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDMyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjIzNiAzLjM2QzcuMjM2IDIuNDk2IDYuOTQ4IDEuODQ4IDYuMzg0IDEuNDA0QzUuODIgMC45NiA1LjEgMC43MzIgNC4yMTIgMC43MzJIMC45MzYwMDFWOUgyLjcyNFY2LjA0OEg0LjIxMkM1LjkyOCA2LjA0OCA3LjIzNiA1LjEzNiA3LjIzNiAzLjM2Wk0zLjgwNCA0LjY4SDIuNzI0VjIuMUg0LjA1NkM1LjAxNiAyLjEgNS40NiAyLjYxNiA1LjQ2IDMuMzM2QzUuNDYgNC4xNTIgNC44NiA0LjY4IDMuODA0IDQuNjhaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTUuMTAxMyA5TDE0Ljk0NTMgNy4wNjhDMTQuODQ5MyA1LjgzMiAxNC4yODUzIDUuMzQgMTMuMDQ5MyA1LjE5NlY1LjA4OEMxNC4xODkzIDQuOTQ0IDE1LjAxNzMgNC4zNTYgMTUuMDE3MyAzQzE1LjAxNzMgMi4yNDQgMTQuNzQxMyAxLjY4IDE0LjE3NzMgMS4zMDhDMTMuNjI1MyAwLjkyNCAxMi45MTczIDAuNzMyIDEyLjA2NTMgMC43MzJIOC43MTcyNVY5SDEwLjUwNTNWNS44OTJIMTEuODI1M0MxMi44NDUzIDUuODkyIDEzLjE4MTMgNi40MDggMTMuMjQxMyA3LjQ1MkwxMy4zMzczIDlIMTUuMTAxM1pNMTEuNjMzMyA0LjUyNEgxMC41MDUzVjIuMUgxMS44MzczQzEyLjE2MTMgMi4xIDEyLjQ4NTMgMi4xNzIgMTIuNzg1MyAyLjMwNEMxMy4wODUzIDIuNDM2IDEzLjI0MTMgMi43NDggMTMuMjQxMyAzLjIxNkMxMy4yNDEzIDQuMDggMTIuNTkzMyA0LjUyNCAxMS42MzMzIDQuNTI0WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTIyLjM4MjMgMC43MzJIMTYuODM4M1Y5SDIyLjM4MjNWNy42MzJIMTguNjI2M1Y1LjQ2SDIxLjM2MjNWNC4wOTJIMTguNjI2M1YyLjFIMjIuMzgyM1YwLjczMloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0yNi40MDA1IDlIMjguNDE2NUwzMS4yOTY1IDAuNzMySDI5LjI5MjVMMjcuNDQ0NSA3LjIxMkgyNy4yODg1TDI1LjI4NDUgMC43MzJIMjMuNDI0NUwyNi40MDA1IDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K") 15 5, auto;
    }
    &-next {
      flex: 0 0 33.33vw;
      cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDMyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjgzMiA2LjE0NEwzLjE2OCAwLjczMTk5OEgwLjkzNjAwMVY5SDIuNTJWMi45MjhMNS41MiA5SDcuMzQ0VjAuNzMxOTk4SDUuODMyVjYuMTQ0WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE0Ljc2NTIgMC43MzE5OThIOS4yMjExNlY5SDE0Ljc2NTJWNy42MzJIMTEuMDA5MlY1LjQ2SDEzLjc0NTJWNC4wOTJIMTEuMDA5MlYyLjFIMTQuNzY1MlYwLjczMTk5OFoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNy45MTkzIDlMMTkuNTk5MyA2LjA2TDIxLjM1MTMgOUgyMy4zOTEzTDIwLjczOTMgNC43NjRMMjMuMjIzMyAwLjczMTk5OEgyMS4xNDczTDE5LjU5OTMgMy42TDE4LjAwMzMgMC43MzE5OThIMTUuOTg3M0wxOC41MzEzIDQuNzY0TDE1LjgwNzMgOUgxNy45MTkzWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTI0LjE5NzMgMC43MzE5OThWMi4xSDI2Ljc2NTNWOUgyOC41MjkzVjIuMUgzMS4wOTczVjAuNzMxOTk4SDI0LjE5NzNaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K") 15 5, auto;
    }
  }
}
</style>
