/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */

import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { InlineSvgPlugin } from 'vue-inline-svg';
import MarqueeText from 'vue-marquee-text-component';

import { loadScript } from '@/assets/js/utils';

import '@/assets/js/eventBus';
import '@/assets/js/raf';
import '@/assets/js/layout';

Vue.config.productionTip = false;

Vue.use(InlineSvgPlugin);

Vue.component('MarqueeText', MarqueeText);
(async () => {
  if (typeof window.__VUE_WORDPRESS__ === 'undefined') {
    const response = await fetch(`${process.env.VUE_APP_PROXY_TARGET}/wp-json/gds/store`);
    window.__VUE_WORDPRESS__ = await response.json();
  }
  const { url: baseUrl } = __VUE_WORDPRESS__.routing;
  Vue.prototype.$relativeUrl = (url) => (url
    ? process.env.NODE_ENV === 'development' && process.env.VUE_APP_PROXY_TARGET
      ? url
        .replace(process.env.VUE_APP_PROXY_TARGET, '')
        .replace(baseUrl, '')
        .replace(process.env.VUE_APP_BASE_URL, '/')
      : url.replace(baseUrl, '').replace(process.env.VUE_APP_BASE_URL, '/')
    : false);

  const router = await import('./router');
  const store = await import('./store');
  const App = await import('./App.vue');
  await import('@/assets/js/mq');

  const app = new Vue({
    router: router.default,
    store: store.default,
    render: (h) => h(App.default),
  });

  sync(store.default, router.default);
  router.default.onReady(() => app.$mount('#app'));
})();

window._iub = [];

window._iub.csConfiguration = {
  lang: 'it',
  siteId: 940041,
  cookiePolicyId: 8252303,
  consentOnScroll: false,
  consentOnContinuedBrowsing: false,
  banner: {
    applyStyles: false,
    position: 'bottom',
    slideDown: 'true',
    content:
      'Utilizziamo cookie e tecnologie simili come specificato nella %{cookie_policy_link}. Interagendo con questa pagina acconsenti all\'utilizzo di tali tecnologie.',
    cookiePolicyLinkCaption: 'Cookie Policy',
    acceptButtonDisplay: true,
    rejectButtonDisplay: true,
    explicitWithdrawal: true,
    closeButtonDisplay: false,
  },
  callback: {
    onConsentGiven: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
      }
    },
    onConsentRejected: () => {
      if (window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        });
      }
    },
    onBannerClosed: () => {
      Vue.prototype.$bus.$emit('iubendaClose');
    },
    onBannerShown: () => {
      Vue.prototype.$bus.$emit('iubendaReady');
    },
  },
  preferenceCookie: {
    expireAfter: 180,
  },
};

loadScript('https://cdn.iubenda.com/cs/iubenda_cs.js');

document.body.addEventListener('click', (e) => {
  if (e.target.classList.contains('check-cookie-consent')) {
    e.preventDefault();
    window._iub.cs.api.openPreferences();
  }
});
