<template>
  <Spacer
    :x="noPadding ? undefined : 'xs'"
    :class="['content', data.theme ? `content--${data.theme}` : false]"
  >
    <div
      v-if="data.theme === 'hero' || (data.theme === 'banner' && !fromList)"
      class="separator--row"
    />

    <div class="content-wrapper">
      <div>
        <Label
          v-if="data.label && data.label !== '' && !fromList"
          :data="{
            value: data.label,
            tag: 'h3',
            size: data.theme === 'banner' ? 'l' : data.label_size || 's',
          }"
          :class="[data.theme === 'banner' ? 'title-content t-uppercase' : false]"
        />

        <Title
          v-if="data.title && data.title !== '' && data.theme !== 'banner'"
          :data="{
            value: data.title,
            tag: 'h2',
            size: 'l',
          }"
          :class="[data.theme === 'hero' ? 't-uppercase' : false]"
        />

        <component
          :is="data.cta ? (data.cta.target === '_blank' ? 'a' : 'router-link') : 'div'"
          v-if="data.image && data.theme === 'banner'"
          class="thumb-cover"
          :to="data.cta && data.cta.target !== '_blank' ? $relativeUrl(data.cta.url) : false"
          :href="data.cta && data.cta.target === '_blank' ? data.cta.url : false"
          :target="data.cta && data.cta.target ? data.cta.target : false"
        >
          <Figure
            :data="{
              ...data.image,
              ratio: false,
              relative: true,
            }"
            disable-caption
          />
        </component>

        <div
          v-if="!onlyImages"
          :class="data.theme === 'banner' && data.image ? 'thumb-content' : false"
        >
          <component
            :is="data.cta ? (data.cta.target === '_blank' ? 'a' : 'router-link') : 'div'"
            v-if="data.theme === 'banner'"
            class="thumb-cover"
            :to="data.cta && data.cta.target !== '_blank' ? $relativeUrl(data.cta.url) : false"
            :href="data.cta && data.cta.target === '_blank' ? data.cta.url : false"
            :target="data.cta && data.cta.target ? data.cta.target : false"
          >
            <Title
              v-if="data.title && data.title !== '' && data.theme === 'banner'"
              :data="{
                value: data.title,
                size: 'l',
              }"
              class="thumb-title"
            />
            <Label
              v-if="data.label && data.label !== ''"
              :data="{
                value: data.label,
                tag: 'h3',
                size: data.theme === 'banner' && !fromList ? 'l' : data.label_size || 's',
              }"
              :class="[
                data.theme === 'banner' && !fromList
                  ? 'title-content t-uppercase'
                  : fromList && data.theme === 'banner'
                    ? 'title-list t-uppercase'
                    : false,
              ]"
            />
          </component>

          <RichText
            v-if="data.rich_text && data.rich_text !== ''"
            class="thumb-text"
            :data="{
              value: data.rich_text,
              size:
                data.theme === 'banner'
                  ? $mq.isMobile
                    ? 's'
                    : 'm'
                  : data.rich_text_size
                    ? data.rich_text_size
                    : theme === 'about'
                      ? 'l'
                      : 'm',
            }"
          />
        </div>
      </div>
    </div>
  </Spacer>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import Figure from '@/components/media/figure';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Figure,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    fromList: {
      type: Boolean,
      default: false,
    },
    onlyImages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['theme']),
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: var(--spacer-xxs);
}
.title {
  margin-bottom: var(--spacer-xs);
}
.separator--row {
  margin-bottom: var(--spacer-xxs);
}

.content--hero {
  position: relative;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: var(--spacer-xs);
    @include mq(s) {
      margin-bottom: var(--spacer-m);
    }
  }

  .cta-wrapper {
    // flex-shrink: 0;
    text-align: right;
  }
}

.content--banner {
  .title-content {
    margin-bottom: var(--spacer-xs);
    @include mq(s) {
      margin-bottom: var(--spacer-m);
    }
  }

  .title-list {
    margin-bottom: var(--spacer-s);
  }
  .thumb-cover {
    display: block;
  }

  .thumb-content {
    padding-top: var(--spacer-xs);
  }

  .thumb-title + .thumb-text {
    margin-top: var(--spacer-xs);
  }
}
</style>
