<template>
  <main class="single-episode">
    <Spacer
      v-if="!overlay"
      :top="liveStatus !== 'video' ? { default: 'xs', s: 'xxl' } : 's'"
      class="u-hidden--until-m"
    />
    <Spacer
      :class="!overlay ? 'grid single-grid' : false"
      :bottom="!overlay ? 'xl' : undefined"
    >
      <div class="single-thumb">
        <ThumbPlayer
          v-show="isAudio"
          :data="post"
          :ratio="!overlay ? 1 : undefined"
          :title="title"
          avoid-link
        />

        <Live
          v-if="post.acf.livestream_share && !isAudio"
          :data="{
            src: post.acf.livestream_share,
            params: {
              enableInfoAndActivity: 'false',
              defaultDrawer: 'feed',
              autoPlay: 'false',
            },
            avoidScroll: true,
          }"
        />
      </div>
      <Spacer
        :template="overlay ? 'wrapper' : undefined"
        :class="[
          'single-wrapper',
          !overlay ? 'grid' : 'single-wrapper-overlay',
        ]"
      >
        <div
          v-if="!overlay"
          class="pre-row"
        >
          <div class="separator--row" />
        </div>
        <div class="single-heading">
          <Flex
            justify="space-between"
            class="heading-meta"
            align="center"
          >
            <Label
              v-if="post.acf.data"
              :data="{ value: post.acf.data, size: 'xxs', tag: 'time' }"
            />

            <Flex column-gap="xs">
              <Cta
                v-if="post.acf.livestream_share"
                :data="{
                  title: isAudio ? 'Video' : 'Audio',
                  fn: toggleMedia,
                }"
                theme="button"
                class="switch"
              />

              <Cta
                :data="{
                  title: 'Condividi',
                  fn: openShare,
                  size: 'xxs',
                }"
                theme="button"
                class="share"
              />
            </Flex>
          </Flex>

          <Title
            :data="{ value: title, size: !overlay ? 'l' : 'm' }"
            class="single-title"
          />

          <Artist :data="post" />

          <Flex
            v-if="post.gds_taxonomies.tags"
            justify="space-between"
            align="baseline"
            class="meta"
          >
            <div
              v-if="post.gds_taxonomies.tags"
              class="tags"
            >
              <Tags
                :data="post.gds_taxonomies.tags"
                :theme="overlay ? 'dark' : 'light'"
                :lite="overlay"
              />
            </div>
          </Flex>
        </div>
        <div class="single-content">
          <RichText
            v-if="content"
            :data="{ value: content.rendered, size: !overlay ? 's' : 'xs' }"
          />

          <div
            v-if="post.acf.show"
            class="show-link"
          >
            <Cta
              :data="{
                title: 'Tutti gli episodi',
                url: post.acf.show.link,
              }"
              theme="rounded-full"
            />
          </div>
        </div>
        <div
          v-if="post.acf.tracklist && post.acf.tracklist.length > 0"
          class="tracklist"
          :style="{ '--rows': Math.floor(post.acf.tracklist.length / 2) + 1 }"
        >
          <div
            v-if="overlay || $mq.isMobile"
            :class="[
              'separator--row',
              overlay ? 'separator--row--light separator--row--extra' : false,
            ]"
          />
          <Spacer bottom="xs">
            <Label :data="{ value: 'Tracklist', size: 'xxs' }" />
          </Spacer>
          <div class="grid-tracklist">
            <div
              v-for="(track, index) in post.acf.tracklist"
              :key="index"
              class="track"
            >
              <Title
                :data="{ value: track.titolo, size: 's' }"
                class="track-artist"
              />
              <Label
                :data="{ value: track.sottotitolo, size: 'xs' }"
                class="track-title"
              />
            </div>
          </div>
        </div>
      </Spacer>
    </Spacer>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Tags from '@/components/typo/tags';
import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';

import Live from '@/components/media/live';

import ThumbPlayer from '@/components/thumb/components/thumb-player';
import Artist from '@/components/thumb/components/artist';

import data from '@/mixins/data';

export default {
  name: 'SingleEpisode',
  components: {
    ThumbPlayer,
    Artist,
    Title,
    RichText,
    Tags,
    Cta,
    Label,
    Live,
  },
  mixins: [data],
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAudio: true,
    };
  },
  computed: {
    ...mapGetters(['liveStatus']),
    title() {
      const title = typeof this.post.title === 'string'
        ? this.post.title
        : this.post.title.rendered;
      if (
        this.post.acf.show
        && this.post.acf.show.title.rendered
        && !title.startsWith(this.post.acf.show.title.rendered)
      ) {
        return `${this.post.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
    content() {
      if (this.post.content && this.post.content.rendered !== '') {
        return this.post.content;
      }
      if (
        this.post.acf.show.content
        && this.post.acf.show.content.rendered !== ''
      ) {
        return this.post.acf.show.content;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.$bus.$emit('removeContentModal', 'share');
  },
  methods: {
    toggleMedia() {
      this.isAudio = !this.isAudio;
    },
    openShare() {
      if (navigator.share) {
        navigator.share({
          title: this.post.title.rendered,
          url: window.location.href,
        });
      } else {
        this.$bus.$emit('modal', {
          type: 'share',
          id: 'share',
          link: window.location.href,
          title: this.post.title.rendered,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.single-episode {
  margin-top: 0;
  .meta {
    margin-bottom: var(--spacer-s);
  }
  .heading-meta {
    margin-bottom: var(--spacer-xs);
  }
  .meta-flex {
    overflow-x: auto;
  }
  .single-title {
    margin-bottom: var(--spacer-xxs);
  }
  .artist {
    margin-bottom: var(--spacer-s);
  }
  .show-link {
    margin-top: var(--spacer-s);
    align-self: end;
  }
  .separator--row {
    margin-bottom: var(--spacer-xxs);
  }

  .single-thumb {
    position: relative;

    .switch {
      /* position: absolute;
      bottom: 10px;
      right: 10px;
      color: var(--white);
      mix-blend-mode: difference; */
    }
  }

  .pre-row {
    margin-bottom: var(--spacer-xxs);

    @include mq($until: m) {
      display: none;
    }

    .separator--row {
      margin-bottom: 0;
    }
  }

  .share {
    text-align: right;

    ::v-deep {
      button {
        margin-left: auto;
      }
    }
  }

  .single-wrapper-overlay {
    .tracklist {
      margin-top: var(--spacer-s);
    }

    .grid-tracklist {
      @include mq(m) {
        columns: 2 auto;
        column-gap: var(--column-gap-l);
      }

      .track {
        break-inside: avoid;
      }
    }
  }

  .tracklist {
    @include mq($until: m) {
      margin-top: var(--spacer-s);
    }

    .track {
      .label {
        margin-top: var(--spacer-xxxs);
        font-size: 12px;
        line-height: 14px;
      }
    }

    .grid-tracklist {
      .track {
        margin-bottom: var(--row-gap-xs);
      }
    }
  }

  .single-grid {
    align-items: start;
    row-gap: var(--row-gap-xs);

    @include mq(m) {
      padding-left: var(--spacer-xs);
      padding-right: var(--spacer-xs);
    }

    .meta {
      margin-bottom: var(--spacer-s);
    }

    .single-thumb {
      grid-column-start: auto;
      grid-column-end: span 12;
      display: block;

      @include mq(m) {
        grid-column-end: span 4;
      }
    }

    .single-wrapper {
      grid-column-start: auto;
      grid-column-end: span 12;

      @include mq($until: m) {
        padding-left: var(--spacer-xs);
        padding-right: var(--spacer-xs);
      }

      row-gap: 0;
      /* min-height: 100%;
      grid-template-rows: repeat(2, auto) 1fr; */

      @include mq(m) {
        grid-column-end: span 8;
      }

      grid-template-columns: repeat(8, minmax(0, 1fr));

      .pre-row,
      .single-heading {
        grid-column-start: auto;
        grid-column-end: span 8;
      }

      .single-heading {
        position: relative;
        min-height: 60px;
        @include mq(m) {
          min-height: 140px;

          .single-title {
            margin-right: 70px;
          }
        }
      }

      .single-content {
        grid-column-start: auto;
        grid-column-end: span 8;

        /* min-height: 100%;
        display: grid;
        grid-template-rows: repeat(2, auto) 1fr; */

        @include mq(m) {
          grid-column-end: span 4;
        }

        min-height: 100%;
        grid-template-rows: repeat(2, auto) 1fr;
      }

      .tracklist {
        grid-column-start: auto;
        grid-column-end: span 8;

        @include mq(m) {
          grid-column-start: 6;
          grid-column-end: span 3;
        }
      }
    }
  }
}
</style>
