<template>
  <div class="thumb">
    <Grid
      class="thumb-content"
      grid="4"
      row-gap="xxs"
    >
      <Column :size="{ default: 4, s: 1 }">
        <Label
          v-if="data.acf.data && type === 'episode'"
          class="meta"
          :data="{ value: data.acf.data, size: 'xxs', tag: 'time' }"
        />
        <!-- <Label
          v-if="type !== 'episode'"
          class="subtype"
          :data="{ value: type, size: 'xxs' }"
        /> -->

        <ThumbPlayer
          :data="data"
          hide
          :title="title"
        />
      </Column>
      <Column :size="{ default: 4, s: 3 }">
        <router-link
          :to="data.link ? $relativeUrl(data.link) : $relativeUrl(data.url)"
          class="title-link"
        >
          <Title :data="{ value: title }" />
        </router-link>

        <Artist :data="data" />

        <div
          v-if="data.gds_taxonomies.tags && type === 'episode'"
          class="tags"
        >
          <Tags
            :data="data.gds_taxonomies.tags"
            lite
          />
        </div>
      </Column>
    </Grid>
  </div>
</template>

<script>
import Artist from '@/components/thumb/components/artist';
import Label from '@/components/typo/label';
import Title from '@/components/typo/title';
import Tags from '@/components/typo/tags';
import ThumbPlayer from '@/components/thumb/components/thumb-player';

export default {
  name: 'ThumbSmall',
  components: {
    Artist,
    Title,
    Label,
    Tags,
    ThumbPlayer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      const title = typeof this.data.title === 'string'
        ? this.data.title
        : this.data.title.rendered;
      if (
        this.data.acf.show
        && this.data.acf.show.title.rendered
        && !title.startsWith(this.data.acf.show.title.rendered)
      ) {
        return `${this.data.acf.show.title.rendered} ${title}`;
      }
      return title;
    },
    type() {
      return this.data.subtype ? this.data.subtype : this.data.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  .title-link {
    display: block;

    .title {
      padding-bottom: var(--spacer-xxs);
    }
  }
  .meta {
    display: block;
    @include mq(m) {
      margin-top: 3px;
    }
  }
  .artist {
    // margin-top: var(--spacer-xxs);
  }
  .tags {
    margin-top: var(--spacer-s);
  }

  &-content {
    position: relative;

    @include mq($until: m) {
      padding-right: 70px;
    }

    @include mq(l) {
      padding-right: 40px;
    }
  }

  .subtype {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--grey-d);
  }

  &-player {
    top: var(--spacer-xxs);
    width: 80px;
    height: 29px;
    color: var(--white);
    max-width: 100%;

    ::v-deep .icon {
      mix-blend-mode: unset;
      top: 0;
      left: 0;
      transform: none;

      @include mq($until: m) {
        width: 100%;
      }
    }

    @include mq($until: m) {
      width: 60px;
      height: 22px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &:hover {
    ::v-deep .icon {
      opacity: 1;
    }
  }
}
</style>
