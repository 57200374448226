<template>
  <component
    :is="tag"
    :class="[
      'flex',
      ...direction,
      ...flexNoWrap,
      ...flexJustify,
      ...flexAlign,
      gap ? 'flex-gap' : false,
      rowGap && rowGap !== '' ? `row-gap-${rowGap}` : false,
      columnGap && columnGap !== '' ? `column-gap-${columnGap}` : false,
    ]"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Flex',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    dir: {
      type: [Object, String],
      default: '',
    },
    nowrap: {
      type: [Object, Boolean],
      default: false,
    },
    gap: {
      type: Boolean,
      default: false,
    },
    rowGap: {
      type: String,
      default: '',
    },
    columnGap: {
      type: String,
      default: '',
    },
    justify: {
      type: [Object, String],
      default: '',
    },
    align: {
      type: [Object, String],
      default: '',
    },
  },
  computed: {
    direction() {
      const { dir } = this;
      const directions = [];
      if (typeof dir === 'string' && dir !== '') {
        directions.push(`dir-${dir}`);
      } else if (typeof dir === 'object') {
        Object.keys(dir).forEach((key) => {
          if (key !== 'default') {
            directions.push(`dir-${key}-${dir[key]}`);
          } else {
            directions.push(`dir-${dir[key]}`);
          }
        });
      }

      return directions;
    },

    flexNoWrap() {
      const { nowrap } = this;
      const nowraps = [];
      if (typeof nowrap === 'boolean' && nowrap) {
        nowraps.push('flex-nowrap');
      } else if (typeof nowrap === 'object') {
        Object.keys(nowrap).forEach((key) => {
          if (key !== 'default' && nowrap[key]) {
            nowraps.push(`flex-nowrap-${key}`);
          } else if (nowrap.default) {
            nowraps.push('flex-nowrap');
          }
        });
      }

      return nowraps;
    },

    flexJustify() {
      const { justify } = this;
      const justifys = [];
      if (typeof justify === 'string' && justify !== '') {
        justifys.push(`justify-${justify}`);
      } else if (typeof justify === 'object') {
        Object.keys(justify).forEach((key) => {
          if (key !== 'default') {
            justifys.push(`justify-${key}-${justify[key]}`);
          } else {
            justifys.push(`justify-${justify[key]}`);
          }
        });
      }

      return justifys;
    },

    flexAlign() {
      const { align } = this;
      const aligns = [];
      if (typeof align === 'string' && align !== '') {
        aligns.push(`align-${align}`);
      } else if (typeof align === 'object') {
        Object.keys(align).forEach((key) => {
          if (key !== 'default') {
            aligns.push(`align-${key}-${align[key]}`);
          } else {
            aligns.push(`align-${align[key]}`);
          }
        });
      }

      return aligns;
    },
  },
};
</script>

<style></style>
