<template>
  <Spacer
    class="accordion-wrapper"
    tag="section"
    x="xs"
  >
    <AccordionWrapper
      v-for="(item, index) in data.items"
      :key="index"
    >
      <AccordionItem>
        <template
          #accordion-trigger="{ active }"
        >
          <Grid>
            <Column
              :size="{ default: 12, s: 3 }"
              class="u-hidden--until-s"
            >
              <div class="separator--row" />
              <Spacer y="xs">
                <Label :data="{ value: item.date, size: 'xs' }" />
              </Spacer>
            </Column>
            <Column :size="{ default: 12, s: 9}">
              <div class="separator--row" />
              <Flex justify="space-between">
                <Spacer y="xs">
                  <Spacer
                    bottom="xs"
                    class="u-hidden--from-s"
                  >
                    <Label :data="{ value: item.date, size: 'xs' }" />
                  </Spacer>
                  <Label :data="{ value: item.label, size: 'xs' }" />
                  <Spacer bottom="xs">
                    <Title :data="{ value: item.title, size: 'm' }" />
                  </Spacer>
                </Spacer>
                <Spacer y="xs">
                  <Label :data="{ value: active ? 'Chiudi' : 'Espandi', size: 'xxs' }" />
                </Spacer>
              </Flex>
            </Column>
          </Grid>
        </template>
        <template slot="accordion-content">
          <Grid>
            <Column
              :size="{ default: 12, s: 9 }"
              :offset="{ s: 3 }"
            >
              <Spacer bottom="s">
                <Richtext :data="{ value: item.rich_text, size: 's' }" />
              </Spacer>
            </Column>
          </Grid>
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </Spacer>
</template>

<script>
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Accordion',
  components: {
    AccordionWrapper,
    AccordionItem,
    Title,
    Label,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.accordion__trigger {
  cursor: pointer;
  &--active {
    .form-group__header,
    .form-wrapper__header,
    .chevron {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .form-group__header,
  .form-wrapper__header,
  .chevron {
    position: relative;
    padding-right: var(--spacer-m);
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: $unit*1.5;
      height: var(--spacer-m);
      background: url('~@/assets/svg/icons/chevron.svg');
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.3s ease;
    }
  }
}

</style>
