export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  TOGGLE_OVERLAY(state, value) {
    if (state.overlay === value) {
      state.overlay = false;
      if (state.singleOverlay) {
        state.overlay = 'episode';
      }
    } else {
      state.overlay = value;
    }
  },
  SET_OVERLAY(state, value) {
    state.overlay = value;
  },
  SET_SINGLE_OVERLAY(state, value) {
    state.singleOverlay = value;
  },
  SET_EMBED(state, value) {
    state.embed = value;
  },
  SET_EMBED_STATUS(state, value) {
    if (state.embed) {
      state.embed.status = value;
    }
  },
  SET_AUDIO(state, value) {
    state.audio = value;
  },
  SET_VIDEO(state, value) {
    state.video = value;
  },
  TOGGLE_LIVE(state, value) {
    state.live = value;
  },
  SET_NOW(state, value) {
    state.now = value;
  },
  TOGGLE_MUTED(state) {
    state.muted = !state.muted;
  },
};
