<template>
  <Spacer
    tag="section"
    bottom="xxl"
    class="newsletter-section"
  >
    <Wrapper
      size="m"
      boxed
    >
      <Spacer
        v-if="data.rich_text && data.rich_text !== ''"
        bottom="s"
      >
        <RichText
          v-if="data.rich_text && data.rich_text !== ''"
          :data="{
            value: data.rich_text,
            size: 'm',
          }"
          class="t-center"
        />
      </Spacer>
      <NewsletterForm section />
    </Wrapper>
  </Spacer>
</template>

<script>
import RichText from '@/components/typo/richtext';
import NewsletterForm from '@/components/ui/newsletter';

export default {
  name: 'Newsletter',
  components: {
    NewsletterForm,
    RichText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.content + {
  .newsletter-section {
    padding-top: var(--spacer-xl);
  }
}
</style>
