var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('Spacer',{attrs:{"top":_vm.liveStatus !== 'video' ? { default: 's', s: '0' } : 's'}}),_c('ArchiveComponent',{attrs:{"data":{
      layout: 'thumb_archive',
      infinite_scroll: true,
      post_type: _vm.archive.name,
      posts_per_scroll: 24,
      thumbs: false,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }